import { useState, useEffect, useMemo } from "react";
import { useAuditLogMutation, useServicePlanHistoryMutation, useServicePlanListMutation, useUpcomingServicePlansMutation, useUpdateServicePlanMutation } from "../../store/slice/ApiSlice";
import { useGeoZoneGridListViewMutation } from "../../store/slice/GeozoneSlice/GeozoneSlice";
import { SoftButton, StyledFormButtonsRow, StyledFormControl, StyledFormControlLabel, StyledPageLayout, StyledTextField } from "../../theme/StyledEle";
import MouseOverPopover from "../shared/PopOver";
import { Breadcrumb } from "../shared/Breadcrumbs";
import FilterDropdown from "../NewDropdown/FilterDropdown";
import { DataTable } from "../shared/data_table/DataTable";
import { Button, CardContent, Chip, Dialog, DialogContent, Icon, Typography, useTheme , Stack, IconButton, FormHelperText} from "@mui/material";
import { StyledCardShadow } from "../../theme/StyledEle";
import { Box } from "@mui/system";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { FiPlus } from "react-icons/fi";
import DrawerModel from "../../Utils/DrawerModel";
import CreateServiceForm from "./CreateServiceForm";
import { MdEdit } from "react-icons/md";
import { AiOutlineDelete } from "react-icons/ai";
import {  update } from "immutable";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import dayjs from "dayjs";
import moment from "moment";
import { snackService } from "../../store/slice/ControlsSlice";
import { alpha } from "@material-ui/core";
import {IoMdClose} from 'react-icons/io';
import { TbEdit } from "react-icons/tb";
import { useForm } from "react-hook-form";



const ListServiceHistory = ({ onEditService, onViewOrg, menuUser }) => {

  const [
    getServicePlanList,{ data: servicePlanData, isLoading: getServicePlanLoading, isSuccess: getServicePlanSuccess },] = useServicePlanHistoryMutation();

    const [
      updateService, {data: updateServiceData, isLoading: updateServicePlanLoading, isSuccess: updateServicPlanSuccess},] = useUpdateServicePlanMutation();

  let userData = JSON.parse(localStorage.getItem("userData"));
  let resellerId = userData.resellerId;
  let orgId = userData.orgId;
  let userId = userData.id;
  let orgName = userData.orgName;
  let userName = userData.userName;
  const [responseData, setResponseData] = useState([]);
  const [newPage, setNewPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [totalElements, setTotalElements] = useState(5);
  const [totalPages, setTotalPages] = useState(1);
  const [payload, setPayload] = useState(null);
  const [auditLog] = useAuditLogMutation();
  const [deleteItem, setDeleteItem] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const theme = useTheme();
  const [commonFilter, setCommonFilter] = useState([]);
  const [selectRows, setSeletedRows] = useState(null);
  const [selectRowId, setSelectRowId] = useState(null);
  const [singleRow, setSinglerow] = useState(null);
  const [isPopup , setIsPopup] = useState(false);
  const [odometerReading ,setOdomterReading] = useState();
  const [clickedval, setClickedval] = useState(false);
  const [date, setDate] = useState(moment(new Date()).format("YYYY-MM-DD"));


  const location = useLocation();

  let auditPayload = {
    orgId: orgId,
    userId: userId,
    orgName: orgName,
    userName: userName,
  }

  useEffect(async () => {
    auditPayload.message = "Service Plan > List Service Plan > GridView";
    auditPayload.action = "View";
    auditPayload.featureName = "Service Plan Grid";
    auditPayload.customerName = "All";
    auditPayload.fleetName = "All";
    auditPayload.result = "Success";
    auditPayload.access = "Portal";
    auditPayload.severity = "INFO";
    auditLog(auditPayload);
  }, []);

  const {
    register,
    formState: { errors },
  } = useForm({
    mode: "all",
  });
  

  const columns = useMemo(() =>
    [
      {
        Header: "Vehicle No",
        accessor: "vehicleNo",
      },
      {
        Header: "Service Type",
        accessor: "serviceTypeLabel",
      },
      {
        Header: " Next Service Date",
        accessor: "serviceDate",
      },
      // {
      //   Header: "Recurrence Type",
      //   accessor: "recurrenceTypeLabel",
      // },
      // {
      //   Header: "Recurrence Distance",
      //   accessor: "recurrenceDistance",
      // },
      {
        Header: "Service Distance",
        accessor: "nextServiceDistance",
      },
      {
        Header: "Service Completion Date",
        accessor: "serviceCompletionDate",
        width: 100,
      },
      {
        Header: "Status",
        accessor: "serviceStatus",
        Cell:({row}) => {
          if(row.original.serviceStatus !== '-' ){
              // return <Typography variant='body1' 
              // color={row.original.serviceStatus ==='Due For Service' ? 'primary':row.original.serviceStatus ==='Completed'? 'success.main':'error.main'}
              // onClick={() => handleCompletePopup(row.original)}>{row.original.serviceStatus}</Typography>
              return <Typography  variant="body2"
              color={row.original.serviceStatus ==='Due for service' ? 'primary':row.original.serviceStatus ==='Completed'? 'success.main':'error.main'}
              sx={{ padding: "0px 3px",fontSize: "12px",fontStyle: "normal", fontWeight: "none" }}>
               {row.original.serviceStatus}</Typography>
          }else{
              return row.original.serviceStatus
          }
        }
      },
      // <Chip label={row.original.serviceStatus} size="small" color={row.original.serviceStatus === 'Completed' ? 'success' : row.original.serviceStatus === 'Due For Service' ? 'primary' : 'error'}/>
      // {
      //   Header: "Recurrence Duration",
      //   accessor: "recurrenceDurationLabel",
      // },

      {
        Header: "Service Action",
        accessor: "buttonEditDelete",
        width: 100,
        Cell:({row}) => {
          if(row.original.serviceStatus !== "Completed"){
          return(
           <IconButton
           size="small"
           sx = {{background:alpha(
            theme.palette.info.main,
            0.1
          ), color:alpha(
            theme.palette.info.main,
            1
          ), borderRadius: "5px"}}
          onClick={(e) => handleCompletePopup(row.original)}
          >
              <TbEdit/>
          </IconButton>
  )}
    else {
      return null;
    }}
      }
    ], [resellerId]);

  let commonPayload = {};
  const onSubmitForm = (data) => {
    setNewPage(0);
    setRowsPerPage(25);
    commonPayload.resellerId = resellerId;
    commonPayload.regionId = data.regionId;
    commonPayload.dealerId = data.dealerId;
    commonPayload.customerId = data.customerId;
    commonPayload.orgId = data.fleetId;
    commonPayload.fleetId = data.fleetId;
    if (data.customerId == -1) {
      commonPayload.orgId = resellerId;
    }
    commonPayload.vehicleId = data.vehicleId;
    commonPayload.search = "";
    commonPayload.sortBy = "desc";
    commonPayload.page = newPage;
    commonPayload.size = rowsPerPage;
    commonPayload.serviceType = -1;
    commonPayload.recurrenceType = -1;

    //Service Call
    setPayload(commonPayload);
    getServicePlanList(commonPayload);

    auditPayload.message = "Service Plan > Upcoming service Plans > " + data.regionName + " > " + data.dealerName + " > " + data.customerName + " > " + data.fleetName + " > " + data.vehicleName + " > " + data.selectedPeriodName + "(" + data.fromDate + "-" + data.toDate + ")" + " > " + "Submit";
    auditPayload.action = "Submit";
    auditPayload.featureName = "ServicePlan";
    auditPayload.customerName = "All";
    auditPayload.fleetName = "All";
    auditPayload.result = "Success";
    auditPayload.access = "Portal";
    auditPayload.severity = "INFO";
    auditPayload.userName = userName;
    //auditLog(auditPayload);
  };

  const handleSearch = async (searchText) => {
    payload.search = searchText;
    if (payload.search.length >= 3) {
      payload.page = 0;
      payload.rowsPerPage = 25;
      setNewPage(0);
      setRowsPerPage(25);
      getServicePlanList(payload);
    } else if (payload.search.length === 0) {
      payload.page = 0;
      payload.rowsPerPage = 25;
      setNewPage(0);
      setRowsPerPage(25);
      getServicePlanList(payload);

    }
  };

  //Page Change
  const pageChange = (newPage, rowsPerPage) => {
    setRowsPerPage(rowsPerPage);
    payload.size = rowsPerPage;
    if (totalElements < rowsPerPage * newPage) {
      setNewPage(0)
      payload.page = 0;
      getServicePlanList(payload);
    } else {
      setNewPage(newPage);
      payload.page = newPage;
      getServicePlanList(payload);
    }
  };

  useEffect(() => {
    if (getServicePlanSuccess) {

      if (servicePlanData.data !== null) {

        setResponseData(servicePlanData.data.content);
        setTotalElements(servicePlanData.data.totalElements);
        setTotalPages(servicePlanData.data.totalPages);

        
        // dataStr.edit = false;
        // dataStr.delete = false;
        // dataStr.view = false;
        // dataStr.excel = false;
        // dataStr.pdf = false;
        // var strval;
        // strval = menuUser.split(",");
        // for (var i = 0; i < strval.length; i++) {
        //   if (parseInt(strval[i]) === 4) {
        //     dataStr.edit = true;
        //   }
        //   if (parseInt(strval[i]) === 3) {
        //     dataStr.delete = true;
        //   }
        //   if (parseInt(strval[i]) === 6) {
        //     dataStr.pdf = true;
        //   }
        //   if (parseInt(strval[i]) === 5) {
        //     dataStr.excel = true;
        //   }
        //   if (parseInt(strval[i]) === 2) {
        //     dataStr.view = false;
        //   }
        //   dataStr.edit = true;
        //   dataStr.view = false;
        // }

        // dataStr.edit =  servicePlanData.data.content.serviceStatus !="Completed" ? true:false;
        // setCommonFilter(dataStr);
      }
    }

  }, [getServicePlanSuccess]);

  useEffect(() => {
    if (updateServicPlanSuccess) {
      dispatch(
        snackService([
          updateServiceData?.resultMessage,
          updateServiceData?.resultText,
          updateServicPlanSuccess,
        ])
      );
      if (updateServiceData?.resultText !== "Failed") {
        setIsPopup(false);
      }
      getServicePlanList(payload);
    }
  },[updateServicPlanSuccess])

  const onEdit = (itemEdited) => {
    handleCompletePopup(itemEdited)

    auditPayload.message = "Admin > ServicePlan > List > Edit";
    auditPayload.action = "Edit";
    auditPayload.featureName = "ServicePlan";
    auditPayload.customerName = "All";
    auditPayload.fleetName = "All";
    auditPayload.result = "Success";
    auditPayload.access = "Portal";
    auditPayload.severity = "INFO";
    auditPayload.userName = userName;
    auditLog(auditPayload);
  };

  const onDelete = async (itemDeleted) => {
    setDeleteItem(itemDeleted);
    navigate(`#/id=${itemDeleted.id}`);
  };


  const onView = async (handleOrgView) => {
    onViewOrg(handleOrgView);

    auditPayload.message = "Admin > ServicePlan > List > View";
    auditPayload.action = "View";
    auditPayload.featureName = "ServicePlan";
    auditPayload.customerName = "All";
    auditPayload.fleetName = "All";
    auditPayload.result = "Success";
    auditPayload.access = "Portal";
    auditPayload.severity = "INFO";
    auditPayload.userName = userName;

    auditLog(auditPayload);
  };

  const getRow = (selectRow, rowId) => {
    setSelectRowId(rowId);
    setSeletedRows(selectRow);
  };


  const handleCompletePopup = (data) => {
    console.log(data)
    setSinglerow(data);
    setIsPopup(true);
  }

  const handleServiceCompletion = (data) => {
     var updatePayload = {};
     updatePayload.createdBy = userId;
     updatePayload.action = "Update";
     updatePayload.id = data.id;
     updatePayload.orgId = data.orgId;
     updatePayload.customerId = data.customerId;
     updatePayload.fleetId = data.fleetId;
     updatePayload.vehicleId  = data.vehicleId;
     updatePayload.vehicleNo = data.vehicleNo;
     updatePayload.serviceType = data.serviceType;
     updatePayload.recurrenceType = data.recurrenceType;
     updatePayload.reminderDurationList = data.reminderList;
     updatePayload.resellerId = resellerId;

     if (data.recurrenceDuration > 0) {
      updatePayload.recurrenceDuration = data.recurrenceDuration;
     }

     if(data.recurrenceDistance != "NA") {
      updatePayload.recurrenceDistance = data.recurrenceDistance;
     }
     if (updatePayload.serviceDate != "NA"){
      updatePayload.serviceDate = date;
     }

     updateService(updatePayload);

  }
  const onClose = (clickaway) => {
    if(clickaway){
      navigate(`${location.pathname}`);
    }
    
  };

  const handleDateChange = (newValue) => {
    setDate(moment(newValue).format("YYYY-MM-DD"));
  };

  return (
    <>
      <FilterDropdown
        getData={onSubmitForm}
        status={false}
        allowDays={7}
        allowToday={true}
        last6Month={true}
        showCalendar={false}

      />
      <Box>
        <StyledCardShadow sx={{ mt: 2 }}>
          <CardContent sx={{ py: 1 }}>
            <DataTable
              onEdit={onEdit}
              onDelete={onDelete}
              columns={columns}
              onView={onView}
              data={responseData}
              styles={{ maxWidth: "100vw" }}
              numberOfStickyColumns={2}
              page={newPage}
              exportWithAll = {false}
              includeSearchBox={false}
              handleChange={handleSearch}
              rowsPerPage={rowsPerPage}
              callbackChangePage={pageChange}
              totalElements={totalElements}
              totalPages={totalPages}
              isLoading={getServicePlanLoading}
              selectRow={getRow}
              
            />
          </CardContent>
        </StyledCardShadow>
    <Dialog open={isPopup} onClose={false} maxWidth={"sm"} fullWidth={true}>
    <DialogContent sx={{ px: 0, py: 2 }}>
      {isPopup &&
      <>
        <Box sx={{ px: 2, py: 1 }}>
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Typography>Service Completion</Typography>

         
            <IconButton color="error" onClick={() => setIsPopup(false)}>
              <IoMdClose />
            </IconButton>
          
          </Stack>
      </Box>
      <Box display={'grid'} justifyContent={'center'} width={'100%'}>
          
        <StyledFormControl sx={{width:"100%"}}>
                                <StyledFormControlLabel required>
                                  Please provide the Date of Service
                                </StyledFormControlLabel>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                  <DesktopDatePicker
                                    inputFormat="yyyy-MM-dd"
                                    maxDate={dayjs().add(0, "day")}
                                    value={date}
                                    onChange={handleDateChange}
                                    renderInput={(params) => (
                                      <StyledTextField
                                        fullWidth
                                        size="small"
                                        {...params}
                                        // inputProps={{
                                        //   ...params.inputProps,
                                        //   placeholder: "Select Date",
                                        // }}
                                        disabled={clickedval}
                                      />
                                    )}
                                  />
                                </LocalizationProvider>
                    </StyledFormControl>
      {singleRow.nextServiceDistance != null && singleRow.nextServiceDistance != "NA" &&
             <StyledFormControl sx={{width:"100%"}}>
                                <StyledFormControlLabel required>
                                   Provide the Odometer Reading on Service
                                </StyledFormControlLabel>
                                <StyledTextField
                                   {...register("nextServiceDistance", {
                                    required: {
                                      value: true,
                                      message: "Please provide the odometer reading on service",
                                    },
                                  })}
                                  size="small"
                                  fullWidth
                                  type="number"
                                  value={odometerReading}
                                  onChange={(e) => setOdomterReading(e.target.value)}
                                  InputProps={{ inputProps: { min: 0 } }}
                                />
                              </StyledFormControl>

                            
          }
                      <FormHelperText error={errors.nextServiceDistance && true} sx={{display:"contents"}}>
                            {errors.nextServiceDistance?.message}
                            </FormHelperText>
                            
                            
      </Box> 
            <StyledFormButtonsRow direction="row" sx={{ mt: 2}} spacing={2}>
          <SoftButton
            size="small"
            color="error"
            className="error"
            onClick={() => setIsPopup(false)}
          >
            Cancel
          </SoftButton>
          <SoftButton
            size="small"
            className="success"
            color="success"
            type="submit"
            onClick={() => handleServiceCompletion(singleRow)}
          >
            Complete
          </SoftButton>
        </StyledFormButtonsRow>
        </>
        }
      </DialogContent>

    </Dialog>
      </Box>
    </>
  );


};


export default ListServiceHistory;