import {
  Box,
  ClickAwayListener,
  Divider,
  InputBase,
  ListItemButton,
  Menu,
  MenuItem,
  SvgIcon,
  useTheme
} from "@mui/material";
import moment from "moment";
import React, { useCallback, useEffect, useLayoutEffect, useState } from "react";
import { DateRangePicker } from "rsuite";
import {
  useGetDropdownListMutation,
  useGetVehicleDropdownListMutation,
} from "../../store/slice/ApiSlice";
import {
  FormIconStack,
  SoftButton,
  StyledFilterFormControl,
  StyledFormControlLabel,
  StyledPaperShadow
} from "../../theme/StyledEle";
import SingleDropdown from "./SingleDropdown";

import { ChargeTypeList, LatestVehicleStatus, Severity, Status, VehicleStatus, dehydrationdropdown, temprise, zoneCategoryList, geozoneTypeDropdown, geozoneCategoryDropdown } from "./MockDropdownData";

import { DatePicker as AntMonthPicker } from "antd";
import { useLocation } from "react-router-dom";
import { Calender } from "../../constants/DualIcons";
import {
  useGetRoutesFilterListMutation
} from "../../store/slice/RouteTripSlice/RouteTripSlice";
import { useGetDriverListMutation } from "../../store/slice/ReportSlice/ReportSlice";

function FilterDropdown(props) {
  //user data form local storage
  const userData = JSON.parse(localStorage.getItem("userData"));
  let resellerId = userData?.resellerId;
  let orgId = userData?.orgId;
  let RoleId = userData?.roleId;
  let userOrgType = userData?.userOrgType;
  let defaultRegion = userData?.defaultRegionId;
  let defaultDealer = userData?.defaultDealerId;
  let defaultCustomer = userData?.defaultCustomerId;
  const theme = useTheme();
  const location = useLocation();


  //Form Props
  const {
    getData,
    isFaultCode = false,
    isVehicleData = false,
    isLatestVehicleData = false,
    isShowAll = true,
    monthPicker = false,
    allowDays,
    last7 = true,
    last31 = false,
    thisMonth = false,
    currentMonth = false,
    showCalendar = true,
    onload = true,
    value = 0,
    setToday = false,
    showRoute = false,
    time = false,
    last6Month = false,
    allowedDays,
    allowedMonth,
    showTime,
    ranges,
    defaultStart,
    timeWithVehicle = true,
    yesToday = false,
    instrumentation = true,
    alignment = null,
    chargeTypeShow = false,
    tempRise = false,
    zoneCategoryShow = false,
    dehydration = false,
    getDataLoading,
    customDate = true,
    offToday = false,
    geozoneCategory = false,
    geozoneType = false,
    dashTab = null,
    DriverShow = false,
  } = props;

  //State for Dropdowns
  const [regionList, setRegionList] = useState(null);
  const [dealerList, setDealerList] = useState(null);
  const [customerList, setCustomerList] = useState(null);
  const [fleetList, setFleetList] = useState(null);
  const [vehicleList, setVehicleList] = useState(null);
  const [routeList, setRouteList] = useState(null);
  const [driverList, setDriverList] = useState(null);

  const [regionName, setRegionName] = useState("All");
  const [dealerName, setDealerName] = useState("All");
  const [customerName, setCustomerName] = useState("All");
  const [fleetName, setFleetName] = useState("All");
  const [vehicleName, setVehicleName] = useState("All");
  const [statusName, setStatusName] = useState("All");
  const [routeName, setRouteName] = useState("All");
  const [driverName, setDriverName] = useState('All');

  //Date Range Picker & Month Picker

  const [showMonthPicker, setShowMonthPicker] = useState(monthPicker);
  useEffect(() => {
    setShowMonthPicker(monthPicker);
  }, [monthPicker])
  //Select from date & To date
  let [label, setLabel] = useState("Today");
  const [showToday, setShowToday] = useState(false);
  const [fromDate, setFromDate] = useState(moment().subtract(1, 'day').format("YYYY-MM-DD 00:00:00"));
  const [toDate, setToDate] = useState(moment().subtract(1, 'day').format("YYYY-MM-DD 23:59:59"));
  const [selectedPriod, setSelectedPriod] = useState(1); // Only for vehicle summary report
  const [openMonthPicker, setOpenMonthPicker] = useState(false);
  //All Single State
  const [region, setRegion] = useState(null);
  const [dealer, setDealer] = useState(null);
  const [customer, setCustomer] = useState(null);
  const [fleet, setFleet] = useState(null);
  const [vehicle, setVehicle] = useState(null);
  const [chargeType, setChargeType] = useState('-1'); //Only Charging Report
  const [vehicleStatus, setVehicleStatus] = useState("9");//Vehicle Data Report
  const [latestVehicleStatus, setLatestVehicleStatus] = useState("9"); //Latest Detail Vehicle Report
  const [severity, setSeverity] = useState("-1"); // Fault Code Report
  const [status, setStatus] = useState("-1"); // Fault Code Report
  const [allOption, setAllOption] = useState(isShowAll);
  const [load, setLoad] = useState(false);
  const [route, setRoute] = useState(null);
  const [zoneType, setZoneType] = useState('All'); //Only for montra
  const [driver, setDriver] = useState('-1');
  //
  const [selectedVehicle, setSelectedVehicle] = useState(null);
  const [selectedRoute, setSelectedRoute] = useState(null);
  const [searchText, setSearchText] = useState({ value: null, name: null });
  const [search, setSearch] = useState(false);
  const [showYesToday, setShowYesToday] = useState(false);
  // charging Report State
  const [temperatureRise, setTemperatureRise] = useState("25");
  const [maxTemp, setMaxTemp] = useState("30");
  const [dehydrationvalue, setDehydrationValue] = useState("5");
  const [geozoneTypeValue, setGeozoneType] = useState("All");
  const [geozoneCategoryValue, setGeozoneCategory] = useState("All");


  useEffect(() => {
    if (getDataLoading) {
      document.body.style.cursor = 'wait'
    } else {
      document.body.style.cursor = 'pointer'
    }
  }, [getDataLoading])


  //set Today
  useEffect(() => {
    if (setToday) {
      setFromDate(moment().format("YYYY-MM-DD 00:00:00"));
      setToDate(moment().format("YYYY-MM-DD 23:59:59"));
      setLabel('Today');
    } else {
      setLabel('Yesterday');
      setFromDate(moment().subtract(1, "days").format("YYYY-MM-DD 00:00:00"));
      setToDate(moment().subtract(1, "days").format("YYYY-MM-DD 23:59:59"));
    }
  }, [setToday])



  //Get Region List
  const [
    getRegionList,
    { data: regionData, isLoading: regionLoading, isSuccess: regionSuccess },
  ] = useGetDropdownListMutation();

  const [
    getDealerList,
    { data: dealerdata, isLoading: dealerLoading, isSuccess: dealerSuccess },
  ] = useGetDropdownListMutation();

  const [
    getCustomerList,
    {
      data: customerdata,
      isLoading: customerLoading,
      isSuccess: customerSuccess,
    },
  ] = useGetDropdownListMutation();
  const [
    getFleetList,
    { data: fleetdata, isLoading: fleetLoading, isSuccess: fleetSuccess },
  ] = useGetDropdownListMutation();

  const [
    getVehicleList,
    { data: vehicledata, isLoading: vehicleLoading, isSuccess: vehicleSuccess },
  ] = useGetVehicleDropdownListMutation();
  const [
    getVehicleSearch,
    {
      data: searchVehicles,
      isLoading: searchVehicleLoading,
      isSuccess: searchVehicleSuccess,
    },
  ] = useGetVehicleDropdownListMutation();

  const [
    getRouteList,
    {
      data: routeListData,
      isLoading: routeListLoading,
      isSuccess: routeListSuccess,
    },
  ] = useGetRoutesFilterListMutation();

  //get Dynaminc Alerts
  const [getDriverList, { data: DriverData, isLoading: DriverListLoading, isSuccess: DriverListSuccess }] = useGetDriverListMutation();

  let CommonPayload = {
    resellerId: resellerId,
  };

  //Treeview Work based this logins
  /* 
  +----+-------------------------+--------+------+
  | ID | ROLE_DESC               | STATUS | TYPE |
  +----+-------------------------+--------+------+
  |  6 | Organization Admin      | Active |    2 |
  |  9 | Customer Admin          | Active |    3 |
  |  20 | Dealer Admin          | Active |    3 |
  */

  //Region List Loaded
  let regionPayload = {
    value: "region",
    searchText: "",
  };
  //Dealor Login Payload
  let DealorPayload = {
    resellerId: resellerId, // Set Login Dealer ID
    value: "customer",
    dealerId: orgId,
    searchText: "",
  };
  //Customer Login Payload
  let CustomerPayload = {
    resellerId: resellerId, // Set Login Customer ID
    value: "fleet",
    dealerId: "0",
    customerId: orgId,
    searchText: "",
  };
  //Fleet Payload
  let FleetPayload = {
    value: "orgId",
    paramValue: "113849", // Set Login Fleet ID
    searchText: "",
  };

  //Based Login Implemented
  const reportsOnload = () => {
    switch (userOrgType) {
      case 2:
        getCustomer(orgId);
        // getRegionList(regionPayload);
        break;
      case 5:
        // getRegionList(regionPayload);
        getCustomer(orgId);
        break;
      case 3:
        getFleet(orgId);
        setCustomer(orgId);
        break;
      case 1:
        getVehicle(orgId);
        setFleet(orgId);
    }
  };

  useEffect(() => {
    reportsOnload();
  }, []);

  const [openRegId, setOpenRegId] = useState(null);
  const [openDealerId, setOpenDealerId] = useState(null);
  const [openCustomerId, setOpenCustomerId] = useState(null);

  //Driver Data
  const DriverCall = async (vehicleId) => {
    try {
      const formData = new FormData();
      formData.append("vehicleId", parseInt(vehicleId));

      const driverData = await getDriverList(formData);
      setDriverList(driverData?.data?.data)
    }
    catch { }
  }

  useEffect(() => {
    if (regionSuccess) {
      setRegionList(regionData?.data);
      if (defaultRegion !== 0 && selectedVehicle === null) {
        getDealer(defaultRegion);
      } else if (!allOption && selectedVehicle === null) {
        getDealer(regionData?.data.content[0].key);
        setLoad(true);
      } else if (search && selectedVehicle !== null) {
        getDealer(selectedVehicle[0].regionId);
      }
    }
    if (allOption && selectedVehicle === null) {
      if (defaultRegion !== 0) {
        getDealer(defaultRegion);
      } else if (userData.resellerId === 109951 && userOrgType !== 3 && userOrgType !== 5 && userOrgType !== 1) {
        //KG 2W
        getDealer("1");
      } else if (userData.resellerId === 110224 && userOrgType !== 3 && userOrgType !== 5 && userOrgType !== 1) {
        getDealer("1");
      }
      else if (!search) {
        setRegion("-1");
      }
    }

  }, [regionSuccess]);

  //getDealer
  const getDealer = (regionId) => {
    if (searchText.name === 'Region') {
      getRegionList(regionPayload);
      setSearchText({ name: null, value: null });
    }
    setRegion(regionId);
    if (regionId !== "-1") {
      CommonPayload.value = "dealer";
      CommonPayload.regionId = regionId;
      CommonPayload.dealerId = "0";
      CommonPayload.searchText = "";
      getDealerList(CommonPayload);
      if (allOption && selectedVehicle === null) {
        setDealer("-1");
        setCustomer("-1");
        setFleet("-1");
        setVehicle("-1");
        setDealerList(null);
        setCustomerList(null);
        setFleetList(null);
        setVehicleList(null);
      }
    } else {
      setDealer("-1");
      setCustomer("-1");
      setFleet("-1");
      setVehicle("-1");
      setDealerList(null);
      setCustomerList(null);
      setFleetList(null);
      setVehicleList(null);
      setSelectedVehicle(null);
    }
  };


  useLayoutEffect(() => {
    if (dealerSuccess) {
      setDealerList(dealerdata?.data);
      if (defaultDealer !== 0 && selectedVehicle === null && region === defaultRegion) {
        getCustomer(defaultDealer);
      } else if (!allOption && selectedVehicle === null) {
        getCustomer(dealerdata?.data.content[0]?.key);
      } else if (search && selectedVehicle !== null) {
        getCustomer(selectedVehicle[0]?.dealerId);
      }
    }
    if (allOption && selectedVehicle === null) {
      if (defaultDealer !== 0 && region === defaultRegion) {
        getCustomer(defaultDealer);
      } else if (resellerId === 109951 && userOrgType !== 3 && userOrgType !== 5 && region === "1") {
        //KG 2W
        getCustomer(113749);
      } else if (userData.resellerId === 110224 && userOrgType !== 3 && userOrgType !== 5 && region === "1") {
        getCustomer(dealerdata?.data.content[0]?.key);
      } else if (!search) {
        setDealer("-1");
      }
    }
  }, [dealerSuccess]);

  //getCustomer
  const getCustomer = (dealerId) => {
    if (dealerId !== undefined) {
      setDealer(dealerId);
    } else {
      setDealer(404);
    }
    if (searchText.name === 'Dealer' && search) {
      DealorPayload.dealerId = '0';
      DealorPayload.value = 'dealer';
      DealorPayload.regionId = region;
      getDealerList(DealorPayload);
      setSearchText({ name: null, value: null });
    }
    if (dealerId !== "-1" && dealerId !== 404) {
      CommonPayload.value = "customer";
      CommonPayload.dealerId = 0;
      CommonPayload.searchText = "";
      getCustomerList(CommonPayload);
      if (allOption && selectedVehicle === null) {
        setCustomer("-1");
        setFleet("-1");
        setVehicle("-1");
        setCustomerList(null);
        setFleetList(null);
        setVehicleList(null);
      }
    } else {
      setCustomer("-1");
      setFleet("-1");
      setVehicle("-1");
      setCustomerList(null);
      setFleetList(null);
      setVehicleList(null);
      setSelectedVehicle(null);
    }
  };
  useLayoutEffect(() => {
    if (customerSuccess) {
      setCustomerList(customerdata?.data);
      if (defaultCustomer !== 0 && selectedVehicle === null && dealer === defaultDealer) {
        getFleet(defaultCustomer);
        setLoad(true);
      }
      else if (!allOption && selectedVehicle === null) {
        getFleet(customerdata?.data?.content[0]?.key);
        let routeListPayload = {
          orgId: customerdata?.data?.content[0]?.key,
          page: 0,
          size: 25,
        };
        var custId = customerdata?.data?.content[0]?.key;
        if (custId !== null && custId !== "-1") {
          getRouteList(routeListPayload);
        }
      } else if (search && selectedVehicle !== null) {
        getFleet(selectedVehicle[0]?.customerId);
      }
    }
    if (allOption && selectedVehicle === null) {
      if (defaultCustomer !== 0 && dealer === defaultDealer) {
        getFleet(defaultCustomer);
      } else if (resellerId === 109951 && userOrgType !== 3 && userOrgType !== 5) {
        //KG 2W
        getFleet(113754);
      } else if (userData.resellerId === 110224 && userOrgType !== 3 && userOrgType !== 5 && region === "1") {
        getFleet(customerdata?.data?.content[0]?.key);
      }
      else if (!search) {
        setCustomer("-1");
      }
    }
  }, [customerSuccess]);

  //getFleet
  const getFleet = (customerId) => {
    if (customerId !== undefined) {
      setCustomer(customerId);
    } else {
      setCustomer(404);
    }
    if (searchText.name === 'Customer' && search) {
      CustomerPayload.value = "customer";
      CustomerPayload.dealerId = dealer;
      CustomerPayload.customerId = null;
      getCustomerList(CustomerPayload);
    }

    if (customerId !== "-1" && customerId !== 404) {
      CommonPayload.value = "fleet";
      CommonPayload.dealerId = "0";
      if (userOrgType === 3) {
        CommonPayload.customerId = orgId;
        CommonPayload.regionId = '0';
      } else {
        CommonPayload.customerId = customerId;
      }
      CommonPayload.searchText = "";
      getFleetList(CommonPayload);
      if (allOption && selectedVehicle === null && userOrgType !== 3) {
        setFleet("-1");
        setVehicle("-1");
        setFleetList(null);
        setVehicleList(null);
      }
    } else {
      setFleet("-1");
      setVehicle("-1");
      setFleetList(null);
      setVehicleList(null);
      setSelectedVehicle(null);
    }
  };

  useLayoutEffect(() => {
    if (fleetSuccess) {
      setFleetList(fleetdata?.data);
      if (!allOption && selectedVehicle === null) {
        getVehicle(fleetdata?.data?.content[0]?.key);
      } else if (search && selectedVehicle !== null) {
        getVehicle(selectedVehicle[0]?.fleetId);
      }
      if (userOrgType === 3 && selectedVehicle === null) {
        getVehicle(fleetdata?.data?.content[0]?.key);
        setFleet(fleetdata?.data?.content[0]?.key);
      }
    }
    if (allOption && selectedVehicle === null && !search && userOrgType !== 3) {
      setFleet("-1");
    }
  }, [fleetSuccess]);


  let vehilePayload = {
    value: "orgId",
    searchText: ""
  };

  //Get Vehicle
  const getVehicle = (fleetId) => {
    if (fleetId !== undefined) {
      setFleet(fleetId);
    } else {
      setFleet(404);
    }
    if (searchText.name === 'Fleet' && search) {
      FleetPayload.value = "fleet";
      FleetPayload.customerId = customer;
      FleetPayload.fleetId = null;
      FleetPayload.dealerId = '0';
      getFleetList(FleetPayload);
    }

    if ((location.pathname === "/geozone" || location.hash === '#view' || location.pathname === "/dashboard") && selectedVehicle === null) {
      setVehicle("-1");
    }
    if (fleetId !== "-1" && fleetId !== 404) {
      vehilePayload.paramValue = fleetId;
      if (search) {
        vehilePayload.headerValue = vehicle;
        vehilePayload.param = 'search';
      }
      getVehicleList(vehilePayload);
    } else {
      setVehicle("-1");
      setVehicleList(null);
      setSelectedVehicle(null);
    }
  };





  //Get Route
  const getRoute = (routeId) => {
    setRoute(routeId);
  };
  useLayoutEffect(() => {
    if (routeListSuccess) {
      setRouteList(routeListData?.data);
      setRoute("0");
    }
  }, [routeListSuccess]);

  useLayoutEffect(() => {
    if (vehicleSuccess) {

      setVehicleList(vehicledata?.data);
      if (location.pathname === "/geozone" || location.hash === '#view' || location.pathname === "/dashboard") {
        getFormData();
      }

      if (search) {
        setSearch(false);
      }

      if (selectedVehicle !== null) {
        setVehicle(selectedVehicle[0]?.key);
        setVehicleList(vehicledata?.data);
      }

      if (
        !allOption &&
        selectedVehicle === null &&
        location.pathname !== "/geozone" && location.hash !== '#view' && location.pathname !== "/dashboard"
      ) {
        selectVehicle(vehicledata?.data?.content[0]?.key);
        setLoad(true);
      }
    }
    if (
      allOption &&
      selectedVehicle === null &&
      location.pathname !== "/geozone" && location.hash !== '#view' && location.pathname !== "/dashboard"
    ) {
      setVehicle("-1");
      if (location.hash !== "#montraDaywise" && userOrgType !== 3 && defaultCustomer === 0) {
        setLoad(true);
      }
    }
  }, [vehicleSuccess]);


  //Select Vehicle
  const selectVehicle = (vehicleId, data) => {
    //setSelectedVehicle(null);
    if (vehicleId !== undefined) {
      setVehicle(vehicleId);
      if (location.pathname === '/driverSummary') {
        DriverCall(vehicleId)
      }
    } else {
      setVehicle(404);
    }
    if (search && vehicleId !== "-1" && vehicleId !== undefined) {
      const SelctedVehicleData = searchVehicles?.data?.content.filter(
        (item) => item.key === vehicleId
      );
      if (!allOption && search) {
        setRegion(SelctedVehicleData[0]?.regionId);
        getDealer(SelctedVehicleData[0]?.regionId);
      }
      if (allOption && search) {
        setRegion(SelctedVehicleData[0]?.regionId);
        getDealer(SelctedVehicleData[0]?.regionId);
        setCustomer(SelctedVehicleData[0]?.customerId);
        setFleet(SelctedVehicleData[0]?.fleetId);
        setVehicle(SelctedVehicleData[0]?.key);
      }
      setSelectedVehicle(SelctedVehicleData);
      setSearchText({ value: null });
    }

    if (vehicleId === "-1" && selectedVehicle !== null && vehicleId !== undefined && !search) {
      setSelectedVehicle(null);
      getVehicle(fleet);
      setVehicle("-1");
    }
  };



  useEffect(() => {
    if (!search) {
      setSelectedVehicle(null);
    }
  }, [search]);

  //Get Vehicle Status
  const getVehicleStatus = (vehicleStatusId) => {
    setVehicleStatus(vehicleStatusId);
  };

  //Get Latest Vehicle Status
  const getLatestVehicleStatus = (latestVehicleStatusId) => {
    setLatestVehicleStatus(latestVehicleStatusId);
  };

  // ---------------- Fault Code -------------------- //
  //Get Vehicle Severity
  const getSeverity = (severityId) => {
    setSeverity(severityId);
  };

  //Get Status
  const getStatus = (severityId) => {
    setStatus(severityId);
  };



  // ---------------- charging Report -------------------- //
  //Get Max Temp
  const getMaxTemp = (maxTemp) => {
    setMaxTemp(maxTemp);
  };
  //Get Temp Rise
  const getTempRise = (tempRise) => {
    setTemperatureRise(tempRise);
    // console.log("tempRaise",tempRaise);
  };


  //Get dehydration
  const getDehydration = (dehyvalue) => {
    setDehydrationValue(dehyvalue);
  };

  const getGeozoneType = (type) => {
    setGeozoneType(type);
  };

  const getGeozoneCategory = (category) => {
    setGeozoneCategory(category);
  };

  //-----------------------------------------------------------------------------------------------------------------
  // vehicleSearch

  const vehicleSearch = useCallback((searchText) => {
    let vehicleSeatchPayload = {
      resellerId: resellerId,
      searchText: searchText,
      page: "0",
      size: "10",
      orgType: userOrgType,
      dealerId: orgId,
      vehicleAllFilter: true,
      imeiSearch: "",
    };
    if (searchText !== '') {
      getVehicleSearch(vehicleSeatchPayload);
    } else {
      setSelectedVehicle(null);
      setVehicleList(vehicledata?.data);
    }
  }, [search]);
  useLayoutEffect(() => {
    if (searchVehicleSuccess) {
      if (searchVehicles.data !== null) {
        setVehicleList(searchVehicles.data);
      }
    }
  }, [searchVehicleSuccess]);

  const regionSearch = (searchText) => {
    CommonPayload.value = "region";
    CommonPayload.searchText = searchText;
    CommonPayload.dealerId = "-1";
    getRegionList(CommonPayload);
  };
  const dealerSearch = (searchText) => {
    CommonPayload.value = "dealer";
    CommonPayload.searchText = searchText;
    CommonPayload.regionId = region;
    if (region !== "-1") {
      getDealerList(CommonPayload);

    }
  };
  const customerSearch = (searchText) => {
    CommonPayload.value = "customer";
    CommonPayload.searchText = searchText;
    CommonPayload.dealerId = dealer;
    if (dealer !== "-1") {
      getCustomerList(CommonPayload);
    }
  };
  const FleetSearch = (searchText) => {
    CommonPayload.value = "fleet";
    CommonPayload.dealerId = "0";
    CommonPayload.searchText = searchText;
    CommonPayload.customerId = customer;
    if (customer !== "-1") {
      getFleetList(CommonPayload);
    }
  };

  //search
  const dropDownSearch = (searchText, name) => {
    setSearchText({ value: searchText, name: name });
    setSearch(true);
    if (name === "Vehicle") {
      vehicleSearch(searchText);
    } else if (name === "Region") {
      regionSearch(searchText);
    } else if (name === "Dealer") {
      dealerSearch(searchText);
    } else if (name === "Customer") {
      customerSearch(searchText);
    } else if (name === "Fleet") {
      FleetSearch(searchText);
    }
  };

  //Date Picker
  //Date picker
  const [anchorEl, setAnchorEl] = useState(null);
  // VSR 
  const [month, setMonth] = useState(false);
  const open = Boolean(anchorEl);
  const { afterToday, allowedRange, allowedMaxDays, combine } = DateRangePicker;
  let currentdate = new Date();
  let currentdate6 = new Date();
  let last3months = new Date(currentdate.setMonth(currentdate.getMonth() - 3));
  let last6months = new Date(currentdate6.setMonth(currentdate6.getMonth() - 6));

  //  //selectDate
  const selectDate = (event) => {
    //ranges
    if (event.target.id === "today") {
      // Sel - 0
      setFromDate(moment().format("YYYY-MM-DD 00:00:00"));
      setToDate(moment().format("YYYY-MM-DD HH:mm:ss"));
      setLabel(event.target.innerText);
      setSelectedPriod(0); // Only for vehicle summary report
      handleClose();
    } else if (event.target.id === "yesterday") {
      // Sel - 1
      setFromDate(moment().subtract(1, "days").format("YYYY-MM-DD 00:00:00"));
      setToDate(moment().subtract(1, "days").format("YYYY-MM-DD 23:59:59"));
      setLabel(event.target.innerText);
      setSelectedPriod(1); // Only for vehicle summary report
      handleClose();
    } else if (event.target.id === "last7days") {
      // Sel - 7
      setFromDate(moment().subtract(7, "days").format("YYYY-MM-DD 00:00:00"));
      setToDate(moment().subtract(1, "days").format("YYYY-MM-DD 23:59:59"));
      setLabel(event.target.innerText);
      setSelectedPriod(7); // Only for vehicle summary report
      handleClose();
    } else if (event.target.id === "lastmonth") {
      // Sel - 3
      setFromDate(
        moment()
          .subtract(1, "month")
          .startOf("month")
          .format("YYYY-MM-DD 00:00:00")
      );
      setToDate(
        moment()
          .subtract(1, "month")
          .endOf("month")
          .format("YYYY-MM-DD 23:59:59")
      );
      setLabel(event.target.innerText);
      setSelectedPriod(30); // Only for vehicle summary report
      handleClose();
    } else if (event.target.id === "thismonth") {
      // Sel - 2
      setFromDate(moment().startOf("month").format("YYYY-MM-DD 00:00:00"));
      setToDate(moment().format("YYYY-MM-DD HH:mm:ss"));
      setLabel(event.target.innerText);
      setSelectedPriod(30); // Only for vehicle summary report
      handleClose();
    } else if (event.target.id === "custom") { /// VSR Date Picker Condition Impleented
      // Sel - 9
      handleClose();
      setSelectedPriod(9); // Only for vehicle summary report
      if ((region === '-1' || vehicle === '-1') && location.pathname === '/VehicleSummary') {
        setMonth(false);
        setOpenCustomCal(true);
      } else {
        setOpenCustomCal(true);
        setMonth(false);
      }

    } else if (event.target.id === 'yesToday') {
      setFromDate(moment().subtract(1, "days").format("YYYY-MM-DD 00:00:00"));
      setToDate(moment().format("YYYY-MM-DD HH:mm:ss"));
      setLabel("Yesterday + Today");
      handleClose();
    } else if (event.target.id === "last31days") {
      // Sel - 7
      setFromDate(moment().subtract(31, "days").format("YYYY-MM-DD 00:00:00"));
      setToDate(moment().subtract(1, "days").format("YYYY-MM-DD 23:59:59"));
      setLabel(event.target.innerText);
      setSelectedPriod(7); // Only for vehicle summary report
      handleClose();
    }
  };


  // //get custom date
  const getCustomDate = (event) => {
    setOpenCustomCal(false);
    if (timeHide && time) {
      setLabel(
        `${moment(event[0]).format("DD-MM-YYYY HH:mm:ss")} - ${moment(
          event[1]
        ).format("DD-MM-YYYY HH:mm:ss")}`
      );
    } else {
      setLabel(
        `${moment(event[0]).format("DD-MM-YYYY 00:00:00")} - ${moment(
          event[1]
        ).format("DD-MM-YYYY 23:59:59")}`
      );
    }
    setFromDate(timeHide && !time ? moment(event[0]).format("YYYY-MM-DD 00:00:00") : timeHide && time ? moment(event[0]).format("YYYY-MM-DD HH:mm:ss") : moment(event[0]).format("YYYY-MM-DD 00:00:00"));
    setToDate(timeHide && !time ? moment(event[1]).format("YYYY-MM-DD 23:59:59") : timeHide && time ? moment(event[1]).format("YYYY-MM-DD HH:mm:ss") : moment(event[1]).format("YYYY-MM-DD 23:59:59"));
  };
  //open custom calendar
  let [openCustomCal, setOpenCustomCal] = useState(false);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    if (openCustomCal) {
      setOpenCustomCal(false);
    }
    if (showMonthPicker) {
      setOpenMonthPicker(true);
    }
  };
  const handleClose = () => {
    setAnchorEl(null);
    setOpenMonthPicker(false);
  };

  const disabledDate = (current) => {
    if (last6Month && resellerId === 110224) {
      return current && current > moment().endOf("day") === current > moment().subtract(6, "month")
    } else if (location.pathname === "/dashboard") {
      return current && current > moment().subtract(1, "month") === current > moment().subtract(6, "month")
    } else {
      return current && current > moment().endOf("day") === current > moment().subtract(3, "month")
    }
  };

  //Select Month
  const selectMonth = (date) => {
    if (month) {
      setLabel(
        `${moment(date).startOf("month").format("YYYY-MM-DD 00:00:00")} - ${moment(date).endOf("month").format("YYYY-MM-DD 23:59:59")}`
      );
    } else {
      setLabel(moment(date).format("MMMM YYYY"));
    }

    setFromDate(moment(date).startOf("month").format("YYYY-MM-DD 00:00:00"));

    if (moment().format("MMMM") === moment(date).format("MMMM")) {
      setToDate(moment().format("YYYY-MM-DD HH:mm:ss"));
      setOpenMonthPicker(false);
    } else {
      setToDate(moment(date).endOf("month").format("YYYY-MM-DD 23:59:59"));
    }
    setOpenMonthPicker(false);
  };

  //onload select month
  useEffect(() => {
    if (showMonthPicker) {
      if (
        moment().startOf("month").format("YYYY-MM-DD") ===
        moment().format("YYYY-MM-DD")
      ) {
        setLabel(moment().subtract(1, "month").format("MMMM YYYY"));
        setFromDate(
          moment()
            .subtract(1, "month")
            .startOf("month")
            .format("YYYY-MM-DD 00:00:00")
        );
        setToDate(
          moment()
            .subtract(1, "month")
            .endOf("month")
            .format("YYYY-MM-DD 23:59:59")
        );
      } else if (location.pathname === '/dashboard') {
        setLabel(moment().subtract(1, "month").format("MMMM YYYY"));
        setFromDate(moment().subtract(1, 'months').startOf('month').format("YYYY-MM-DD 00:00:00"));
        setToDate(moment().subtract(1, 'months').endOf('month').format("YYYY-MM-DD 23:59:59"));
      } else {
        setLabel(moment().format("MMMM YYYY"));
        setFromDate(moment().startOf("month").format("YYYY-MM-DD 00:00:00"));
        setToDate(moment().format("YYYY-MM-DD HH:mm:ss"));
      }
    }
  }, [showMonthPicker]);

  //Show today only individual
  useEffect(() => {
    if (vehicle !== "-1") {
      if (!showMonthPicker) {
        if (setToday) {
          setLabel("Today");
          setFromDate(moment().format("YYYY-MM-DD 00:00:00"));
          setToDate(moment().format("YYYY-MM-DD HH:mm:ss"));
          setSelectedPriod(0);
        } else if (location.pathname === "/dashboard") {
          setLabel("Yesterday");
          setFromDate(
            moment().subtract(1, "days").format("YYYY-MM-DD 00:00:00")
          );
          setToDate(moment().subtract(1, "days").format("YYYY-MM-DD 23:59:59"));
          setSelectedPriod(1);
        }
      }
      setShowToday(true);
      if (location.hash === '#view') {
        setShowYesToday(false)
      }
    } else {
      if (!showMonthPicker && !setToday) {
        setShowToday(false);
        setShowYesToday(false)
        setLabel("Yesterday");
        setFromDate(moment().subtract(1, "days").format("YYYY-MM-DD 00:00:00"));
        setToDate(moment().subtract(1, "days").format("YYYY-MM-DD 23:59:59"));
        setSelectedPriod(1);
      }
    }
  }, [vehicle, showMonthPicker]);


  useEffect(() => {
    if (!allOption && load && fleet && vehicle && onload) {
      getFormData();
    }
  }, [vehicle, fleet]);

  useEffect(() => {
    if (userOrgType === 3 && selectVehicle === null) {
      setFleet(fleetdata?.data?.content[0]?.key);
    }
  }, [fleetdata]);
  const [vehFlag, setVehFlag] = useState(false);
  //Montra Daywise report
  useEffect(() => {
    if (location.hash === '#montraDaywise' && !vehFlag) {
      setVehFlag(true);
      setLabel('Last 7 Days');
      setFromDate((prevState) => moment().subtract(7, "days").format("YYYY-MM-DD 00:00:00"));
      setToDate((prevState) => moment().subtract(1, "days").format("YYYY-MM-DD 23:59:59"));
      //setLoad(true);
    } else if (location.pathname === '/VehicleSummary' && userData.resellerId !== 110224 && userData.resellerId !== 109951) {
      setLoad(true);
    }
  }, [vehFlag, location.hash])
  //GEO Zone
  useEffect(() => {
    if ((location.pathname === "/geozone" || location.hash === '#view' || location.pathname === "/dashboard") && onload) {
      getFormData();
    }
  }, []);

  useEffect(() => {
    if ((location.pathname === '/VehicleSummary' || location.hash === '#montraDaywise') && customer !== null && customer !== '-1' && customer !== undefined) {
      getFormData();
    }
  }, [fromDate, customer]);



  const getInnerText = (value, label) => {
    if (label === "Region") {
      setRegionName(value);
    } else if (label === "Dealer") {
      setDealerName(value);
    } else if (label === "Customer") {
      setCustomerName(value);
    } else if (label === "Fleet") {
      setFleetName(value);
    } else if (label === "Vehicle") {
      setVehicleName(value);
    } else if (label === "Status") {
      setStatusName(value);
    } else if (label === "Route") {
      setRouteName(value);
    } else if (label === "Driver") {
      setDriverName(value);
    }
  }


  //getForm Data
  const getFormData = () => {
    let FormData = {
      regionId: 0,
      dealerId: 0,
      customerId: userOrgType === 3 ? orgId : userOrgType === 1 ? 0 : customer,
      fleetId: userOrgType === 1 ? orgId : fleet,
      vehicleId: vehicle,
      vehicleStatusId: vehicleStatus,
      latestVehicleStatusId: latestVehicleStatus,
      severityId: severity,
      status: status,
      fromDate: fromDate,
      toDate: label === 'Today' ? moment().format('yyyy-MM-DD HH:mm:ss') : toDate,
      selectedPriod: selectedPriod,
      routeId: route,
      chargeType: chargeType,
      regionName: regionName === "" ? "-" : regionName,
      dealerName: dealerName === "" ? "-" : dealerName,
      customerName: customerName === "" ? "-" : customerName,
      fleetName: fleetName === "" ? "-" : fleetName,
      vehicleName: vehicleName === "" ? "-" : vehicleName,
      statusName: statusName === "" ? "-" : statusName,
      selectedPeriodName: label,
      routeName: routeName,
      maxTemp: maxTemp,
      tempRise: temperatureRise,
      zoneCategory: zoneType,
      sbatteryhealth: dehydrationvalue,
      zoneType: geozoneTypeValue,
      zoneCategory: geozoneCategoryValue,
      driverId: driver,

    };
    //Send data to report
    getData(FormData);
  };




  // useEffect(() => {
  //   if (location.hash === '#montraDaywise' && load) {
  //     getFormData();
  //   }
  // },[load, location.hash]);


  ///Get Time & Date

  let [newFromDate, setNewFromDate] = useState(null);
  let [newendDate, setNewendDate] = useState(null);

  const getTimeAndDate = (start, end) => {
    setNewFromDate(moment(start).format('YYYY-MM-DD HH:mm:ss'));
    setNewendDate(moment(end).format('YYYY-MM-DD HH:mm:ss'));
  }

  //Onload Time get Data
  useEffect(() => {
    if (
      load &&
      allOption &&
      region !== null &&
      userData.resellerId !== 109951 && userData.resellerId !== 110224 &&
      location.pathname === '/VehicleSummary'
    ) {
      getFormData();
    } else if (load && region !== null && userData.resellerId !== 109951 && userData.resellerId !== 110224 && userOrgType !== 3 && location.pathname !== '/VehicleSummary' && location.pathname !== '/route') {
      getFormData();
    }
  }, [load]);



  useEffect(() => {
    if (
      load &&
      region !== null &&
      (userData.resellerId === 109951 || userData.resellerId === 110224) &&
      dealer !== "-1" &&
      customer !== "-1" && userOrgType !== 3 &&
      location.pathname !== '/VehicleSummary'
    ) {
      getFormData();
    }
  }, [customer]);

  // setfleet run
  useEffect(() => {
    if (userOrgType === 1 && fleet !== '-1' && fleet !== '0') {
      getFormData();
    }
  }, [fleet])

  // setCustomer run
  useEffect(() => {
    if (userOrgType === 3 && fleet !== '-1') {
      getFormData();
    }
  }, [fleet])

  //Click away
  const clickAway = () => {
    if (month) {
      setMonth(false);
    }
    if (openCustomCal) {
      setOpenCustomCal(false);
    } else {
      setOpenMonthPicker(false);
    }

    //setOpenMonthPicker(false)
  };

  let text = document.getElementsByClassName('rs-picker-toolbar');
  const addText = () => {
    var type = document.createElement('div')
    type.innerHTML = `<p class='customtext'>Maximum custom range is ${allowDays} ${allowDays === 1 ? 'day' : 'days'}  within the last ${allowedMonth} months</p>`
    text[0].prepend(type)
  }

  useEffect(() => {
    if (text.length > 0) {
      addText();
    }
    if (vehicle !== "-1" && timeHide && time) {
      setTimeFormat('dd-MM-yyyy HH:mm:ss')
    }
  }, [openCustomCal])

  //Time with vehicle
  let [timeHide, setTimeHide] = useState(false);

  useEffect(() => {
    if (timeWithVehicle && vehicle !== '-1') {
      setTimeHide(true);
    } else {
      setTimeHide(false);

    }
  }, [vehicle, timeWithVehicle])

  useEffect(() => {
    let getTimePan = document.getElementsByClassName('rs-calendar-header-time-toolbar');
    let getDatePan = document.getElementsByClassName('rs-picker-daterange-calendar-group');
    if (getDatePan[0]?.style !== undefined && getTimePan[0]?.style !== undefined) {
      if (openCustomCal && vehicle !== '-1' && time && timeWithVehicle) {
        getDatePan[0]?.classList?.add('addTimerPicker');
        getTimePan[0].style.display = 'block';
        getTimePan[1].style.display = 'block';
      } else if (vehicle === '-1' && getDatePan[0]?.style !== undefined) {
        getTimePan[0].style.display = 'none';
        getTimePan[1].style.display = 'none';
        getDatePan[0]?.classList?.remove('addTimerPicker');
      }
    }

  }, [openCustomCal, vehicle, time])


  let [timeFormat, setTimeFormat] = useState(time ? 'dd-MM-yyyy HH:mm:ss' : 'dd-MM-yyyy');

  useEffect(() => {
    if (alignment !== null) {
      setLabel("Yesterday");
      setFromDate(moment().subtract(1, "days").format("YYYY-MM-DD 00:00:00"));
      setToDate(moment().subtract(1, "days").format("YYYY-MM-DD 23:59:59"));
      setSelectedPriod(1);
      if (selectedVehicle === null && vehicle === '-1') {
        setVehicle(vehicledata?.data?.content[0]?.key);
      }

    }
  }, [alignment]);

  useEffect(() => {
    if (alignment !== null) {
      setTimeout(() => {
        getFormData();
      }, 100)
    }

  }, [fromDate, alignment, vehicle])

  useEffect(() => {
    if (dashTab !== null) {
      getFormData();
    }

  }, [fromDate, dashTab, showMonthPicker])

  // setCustomer run
  useEffect(() => {
    if (location.pathname === '/playback' && vehicle !== null) {
      getFormData();
    }
  }, [vehicle])

  return (
    <StyledPaperShadow
      elevation={0}
      sx={{ display: "inline-block", width: "100%" }}
    >
      {userOrgType !== 9 && userOrgType !== 5 && userOrgType !== 3 && userOrgType !== 1 && instrumentation && userOrgType !== 2 && (
        <SingleDropdown
          label={"Region"}
          listItems={regionList}
          handleChange={getDealer}
          value={region}
          isSearch={dropDownSearch}
          showAll={allOption}
          getInnerText={getInnerText}
          isLoading={regionLoading}
        />
      )}

      {userOrgType !== 9 && userOrgType !== 5 && userOrgType !== 3 && userOrgType !== 1 && instrumentation && userOrgType !== 2 && (
        <SingleDropdown
          label={"Dealer"}
          listItems={dealerList}
          handleChange={getCustomer}
          value={dealer}
          isSearch={dropDownSearch}
          showAll={allOption}
          getInnerText={getInnerText}
          isLoading={dealerLoading}
        />
      )}

      {userOrgType !== 3 && userOrgType !== 1 && instrumentation && (
        <SingleDropdown
          label={"Customer"}
          listItems={customerList}
          handleChange={getFleet}
          value={customer}
          isSearch={dropDownSearch}
          showAll={allOption}
          getInnerText={getInnerText}
          isLoading={customerLoading}
        />
      )}
      {userOrgType !== 1 && instrumentation &&
        <SingleDropdown
          label={"Fleet"}
          listItems={fleetList}
          handleChange={getVehicle}
          value={fleet}
          isSearch={dropDownSearch}
          showAll={userOrgType === 3 ? false : allOption}
          getInnerText={getInnerText}
          isLoading={fleetLoading}
        />
      }

      {showRoute === true && (
        <SingleDropdown
          label={"Route"}
          listItems={routeList}
          handleChange={getRoute}
          value={route}
          isSearch={dropDownSearch}
          showAll={true}
          customAllValue={"0"}
          getInnerText={getInnerText}
        />
      )}

      {value === 0 && instrumentation && (
        <SingleDropdown
          label={"Vehicle"}
          listItems={vehicleList}
          onClick={() => setSearch(false)}
          handleChange={selectVehicle}
          value={selectedVehicle !== null ? selectedVehicle[0]?.key : vehicle}
          isSearch={dropDownSearch}
          searchText={searchText}
          showAll={
            (location.pathname === "/geozone" || location.hash === '#view' || location.pathname === "/dashboard") && vehicleList !== null
              ? true
              : allOption
          }
          getInnerText={getInnerText}
          isLoading={vehicleLoading}
        />
      )}

      {
        chargeTypeShow &&
        <SingleDropdown
          label={"Charging Type"}
          listItems={ChargeTypeList}
          onClick={() => setSearch(false)}
          handleChange={(value) => setChargeType(value)}
          value={chargeType}
          isSearch={dropDownSearch}
          searchText={searchText}
          showAll={true}
        />
      }

      {isVehicleData && (
        <SingleDropdown
          label={"Status"}
          listItems={VehicleStatus}
          handleChange={getVehicleStatus}
          value={vehicleStatus}
          isSearch={false}
          searchText={searchText}
          showAll={false}
          getInnerText={getInnerText}
        />
      )}

      {isLatestVehicleData && (
        <SingleDropdown
          label={"Status"}
          listItems={LatestVehicleStatus}
          handleChange={getLatestVehicleStatus}
          value={latestVehicleStatus}
          isSearch={false}
          searchText={searchText}
          showAll={false}
          getInnerText={getInnerText}
        />
      )}

      {isFaultCode && (
        <SingleDropdown
          label={"Severity"}
          listItems={Severity}
          handleChange={getSeverity}
          value={severity}
          isSearch={false}
          searchText={searchText}
          showAll={true}
        />
      )}
      {isFaultCode && (
        <SingleDropdown
          label={"Status"}
          listItems={Status}
          handleChange={getStatus}
          value={status}
          isSearch={false}
          searchText={searchText}
          showAll={true}
        />
      )}
      {tempRise && (
        <SingleDropdown
          label={"Temperature Rise"}
          listItems={temprise}
          handleChange={getTempRise}
          value={temperatureRise}
          isSearch={false}
          searchText={searchText}
          showAll={true}
        />

      )}
      {tempRise && (
        <SingleDropdown
          label={"Max Temperature"}
          listItems={temprise}
          handleChange={getMaxTemp}
          value={maxTemp}
          isSearch={false}
          searchText={searchText}
          showAll={true}
        />

      )}


      {
        zoneCategoryShow &&
        <SingleDropdown
          label={"Zone Category"}
          listItems={zoneCategoryList}
          onClick={() => setSearch(false)}
          handleChange={(value) => setZoneType(value)}
          value={zoneType}
          isSearch={false}
          searchText={searchText}
          showAll={true}
          customAllValue={"All"}
        />
      }


      {dehydration && (
        <SingleDropdown
          label={"Degradation"}
          listItems={dehydrationdropdown}
          handleChange={getDehydration}
          value={dehydrationvalue}
          isSearch={false}
          searchText={searchText}
          showAll={true}
        />

      )}

      {geozoneType && (
        <SingleDropdown
          label={"Type"}
          listItems={geozoneTypeDropdown}
          handleChange={getGeozoneType}
          value={geozoneTypeValue}
          isSearch={false}
          searchText={searchText}
          showAll={true}
          customAllValue={'All'}
        />
      )
      }

      {geozoneCategory && (
        <SingleDropdown
          label={"Category"}
          listItems={geozoneCategoryDropdown}
          handleChange={getGeozoneCategory}
          value={geozoneCategoryValue}
          isSearch={false}
          searchText={searchText}
          showAll={true}
          customAllValue={'All'}
        />
      )
      }
      {
        DriverShow &&
        <SingleDropdown
          label={"Driver"}
          listItems={driverList}
          handleChange={(driverId) => setDriver(driverId)}
          value={driver}
          isSearch={false}
          showAll={true}
          getInnerText={getInnerText}
        />
      }

      {/* Date Range Picker */}
      {showCalendar && (
        <StyledFilterFormControl className="dateRangePicker">
          <StyledFormControlLabel>
            {showMonthPicker ? "Select Month" : "Date"}
          </StyledFormControlLabel>
          <ClickAwayListener onClickAway={clickAway}>
            <FormIconStack direction={"row"} alignItems={"center"} spacing={.5}>
              <SvgIcon
                sx={{
                  fontSize: "20px",
                  color:
                    theme.palette.mode === "light"
                      ? theme.palette.secondary.main
                      : theme.palette.grey[300],
                }}
              >
                <Calender />
              </SvgIcon>
              <Box className="dateRangePicker_box">
                {showMonthPicker ? (
                  <AntMonthPicker
                    picker="month"
                    disabledDate={disabledDate}
                    open={openMonthPicker}
                    onChange={selectMonth}
                    defaultValue={
                      moment().startOf("month").format("DD-MM-YYYY") ===
                        moment().format("DD-MM-YYYY")
                        ? moment().subtract(1, "days")
                        : location.pathname === '/dashboard' ? moment().subtract(1, "month") : moment()
                    }
                    minDate={disabledDate}
                    maxDate={location.pathname === '/dashboard' ? moment().subtract(1, "month") : moment()}
                  />
                ) : (
                  month ?
                    <AntMonthPicker
                      picker="month"
                      disabledDate={disabledDate}
                      open={month}
                      onChange={selectMonth}
                      defaultValue={
                        moment().startOf("month").format("DD-MM-YYYY") ===
                          moment().format("DD-MM-YYYY")
                          ? moment().subtract(1, "days")
                          : moment(fromDate)
                      }
                      minDate={disabledDate}
                      maxDate={moment()}
                    />
                    :
                    <DateRangePicker
                      format={timeFormat}
                      // /container={'body'}
                      disabledDate={combine(
                        allowDays === 0
                          ? allowedMaxDays()
                          : allowedMaxDays(allowDays),
                        allowedRange(last3months, vehicle !== '-1' ? moment() : moment().subtract(1, "days"))
                      )}
                      placeholder="Select Date Range"
                      placement="bottomEnd"
                      open={openCustomCal}

                      onOk={(event) => getCustomDate(event)}
                      onClose={() => setOpenCustomCal(false)}
                      ranges={[]}
                      size="xs"
                      className={`CustomDateRangePicker`}
                      preventOverflow={true}
                      popupStyle={{
                        background: 'red',
                      }}
                      value={[new Date(moment(fromDate)), new Date(moment(toDate))]}
                    // defaultCalendarValue={[new Date("204-03-25"), new Date(toDate)]}
                    />
                )}
                <InputBase
                  value={label}
                  readOnly
                  title={`${fromDate} - ${toDate}`}
                  size="small"
                  id="basic-button"
                  aria-controls={open ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  onClick={(event) => handleClick(event)}
                  autoComplete="off"
                  sx={{
                    padding: "5.5px 5px",
                    [theme.breakpoints.down("xl")]: {
                      fontSize: "10px",
                    },
                  }}
                />
              </Box>
              {showMonthPicker !== true && (
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                  PaperProps={{
                    sx: {
                      "&.MuiPaper-root": {
                        width: "250px",
                      },
                      "& .MuiButtonBase-root ": {
                        fontWeight: 400,
                      },
                    },
                  }}
                >
                  {(showToday || setToday) && offToday && (
                    <ListItemButton
                      onClick={(event) => selectDate(event)}
                      id="today"
                      dense={true}
                      selected={label === "Today"}
                    >
                      Today
                    </ListItemButton>
                  )}

                  <MenuItem
                    onClick={(event) => selectDate(event)}
                    id="yesterday"
                    dense={true}
                    selected={label === "Yesterday"}
                  >
                    Yesterday
                  </MenuItem>
                  {
                    (showYesToday || yesToday) &&
                    <MenuItem
                      onClick={(event) => selectDate(event)}
                      id="yesToday"
                      dense={true}
                      selected={label === "Yesterday + Today"}
                    >
                      Yesterday + Today
                    </MenuItem>
                  }
                  {last7 && (
                    <MenuItem
                      onClick={(event) => selectDate(event)}
                      id="last7days"
                      dense={true}
                      selected={label === "Last 7 Days"}
                    >
                      Last 7 Days
                    </MenuItem>
                  )}
                  {last31 && (
                    <MenuItem
                      onClick={(event) => selectDate(event)}
                      id="last31days"
                      dense={true}
                      selected={label === "Last 30 Days"}
                    >
                      Last 30 Days
                    </MenuItem>
                  )}
                  {thisMonth && (
                    <MenuItem
                      onClick={(event) => selectDate(event)}
                      id="thismonth"
                      dense={true}
                      selected={label === "Current Month"}
                    >
                      Current Month
                    </MenuItem>
                  )}
                  {currentMonth && (
                    <MenuItem
                      onClick={(event) => selectDate(event)}
                      id="lastmonth"
                      dense={true}
                      selected={label === "Last Month"}
                    >
                      Last Month
                    </MenuItem>
                  )}
                  {customDate && (
                    <ListItemButton
                      onClick={(event) => selectDate(event)}
                      id="custom"
                      dense={true}
                      selected={
                        label !== "Today" &&
                        label !== "Yesterday" &&
                        label !== "Last 7 Days" &&
                        label !== "Last Month" &&
                        label !== "Current Month" &&
                        label !== "Yesterday + Today" &&
                        label !== "Last 30 Days"
                      }
                    >
                      Custom
                    </ListItemButton>
                  )}
                  <Divider />
                  {/* <ListItem dense={true}>
                    <ListItemText>
                      {allowDays === 7
                        ? `Maximum custom range is 7 days within the last ${last6Month ? '6' : '3'} months `
                        : allowDays === 30
                        ? `Maximum custom range is 30 days within the last ${last6Month ? '6' : '3'} months`
                        : `Maximum custom range is 1 day within the last ${last6Month ? '6' : '3'} months`}
                    </ListItemText>
                  </ListItem> */}
                </Menu>
              )}
            </FormIconStack>
          </ClickAwayListener>
        </StyledFilterFormControl>

        // <>
        // <BootsrapDateRange
        //   getTimeAndDate={getTimeAndDate}
        //   allowedDays = {allowedDays}
        //   allowedMonth = {allowedMonth}
        //   showTime = {showTime}
        //   defaultStart = {defaultStart}
        //   ranges={ranges}
        //  />
        // </>

      )}
      {/* Submit */}
      <SoftButton
        type="button"
        variant="contained"
        size="small"
        color="success"
        className="success"
        onClick={getFormData}
        sx={{ marginTop: "25px", cursor: getDataLoading ? 'wait' : 'pointer' }}
      >
        Submit
      </SoftButton>

      {/* Date Picker Menu */}
    </StyledPaperShadow>
  );
}

export default FilterDropdown;