import * as React from "react";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import HelpIcon from "@mui/icons-material/Help";
import Box from "@mui/material/Box";
import { Alert, AlertTitle, alpha, Button, Collapse, Divider, IconButton, Stack } from "@mui/material";
import { IoCloseCircleOutline } from "react-icons/io5";
import { StyledAlert, StyledPaperShadow } from "../../theme/StyledEle";
import { useTheme } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { togglePopup } from "../../store/slice/ControlsSlice";
export default function MouseOverPopover({ text, title }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const theme = useTheme();
  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const popupState = useSelector((state) => state.controls.customizer.popupIsHide)
  const dispatch = useDispatch();
  const hidePopup = () => {
    dispatch(togglePopup(!popupState));
  }
  React.useEffect(() => {
    dispatch(togglePopup(false));
  },[])
  return (
    <Collapse in={popupState}>
    <StyledAlert
      severity="info"
      elevation={0}
      action={
        <IconButton color="inherit" size="small" onClick={hidePopup}>
            <IoCloseCircleOutline/>
        </IconButton>
      }
      sx={{
        background:`${alpha(theme.palette.info.main, .05)} !important`,
        borderColor:alpha(theme.palette.info.main, .1),
      }}
    >
      <Stack direction='row' divider={<Divider orientation="vertical" flexItem />} spacing={2}>
        <Typography variant="body2">{text}</Typography>
      </Stack>
    </StyledAlert>
    </Collapse>
  );
}
