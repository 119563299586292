import {
  CardContent,
  Typography
} from "@mui/material";
import { useTheme } from "@mui/styles";
import { Box } from "@mui/system";
import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  useAuditLogMutation,
  useTripMutation,
} from "../../store/slice/ApiSlice";
import {
  StyledCardShadow,
  StyledPageLayout
} from "../../theme/StyledEle";
import FormDialog from "../../Utils/FormDialog";
import GetLocation from "../Location";
import FilterDropdown from "../NewDropdown/FilterDropdown";
import { Breadcrumb } from "../shared/Breadcrumbs";
import { DataTable } from "../shared/data_table/DataTable";
import MouseOverPopover from "../shared/PopOver";

const text =
  "A Trip is defined as distance traveled by the vehicle between ignition on and off state. The minimum distance travelled must be greater than 200m to be considered in this trip report.";

const TripReport = () => {
  const navigate = useNavigate();
  let userData = JSON.parse(localStorage.getItem("userData"));
  let resellerId = userData?.resellerId;
  let orgId = userData?.orgId;
  let userId = userData?.id;
  let orgName = userData?.orgName;
  let userName = userData?.userName;
  let displayDistance = userData.distanceDisplayUnit ;


  const [responseData, setResponseData] = useState([]);
  const [newPage, setNewPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [totalElements, setTotalElements] = useState(5);
  const [totalPages, setTotalPages] = useState(1);

  const [auditLog] = useAuditLogMutation();
  let auditPayload = {
    //Audit Common Payload
    orgId: orgId,
    userId: userId, //User ID
    orgName: orgName, //Org Name
    userName: userName,
  };
  const theme = useTheme();

  useEffect(async () => {
    // await fetchTripReportReportDetails();
    // var request = {};
    // request.message = "Reports > TripReport";
    // request.action = "View";
    // request.featureName = "TripReport";
    // request.customerName = "All";
    // request.fleetName = "All";
    // request.result = "Success";
    // request.access = "Portal";
    // request.severity = "INFO";
    // request.orgId = globalOrgId;
    // request.userId = globaluserId;
    // request.orgName = globalOrgName;
    // request.userName= globalUserName;
    //const responseData = await logServices.saveAuditLogDetails(request);
    auditPayload.message = "Reports > Trip Report";
    auditPayload.action = "View";
    auditPayload.featureName = "Trip Report";
    auditPayload.customerName = "All";
    auditPayload.fleetName = "All";
    auditPayload.result = "Success";
    auditPayload.access = "Portal";
    auditPayload.severity = "INFO";
    auditLog(auditPayload);
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: "Vehicle Number",
        accessor: "vehicleNo",
        width:180,
      },

      {
        Header: "Start Location",
        accessor: "startLocation",
        width:180,
        Cell: ({ row }) => {
          return(
            row.original.startLocation !== "" &&   row.original.startLocation !== "NA" ?
          <GetLocation row={row} columnName={row.original.startLocation}/> : '-'
          )
        }
      },
      {
        Header: "End Location",
        accessor: "endLocation",
        width:180,
        Cell: ({ row }) => {
          return(
            row.original.endLocation !== "" &&   row.original.endLocation !== "NA" ?
          <GetLocation row={row} columnName={row.original.endLocation}/> : '-'
          )
        }
      },
      {
        Header: "Start Odometer (" + displayDistance+")" ,
        accessor: "tripStartOdometer",
      },
      {
        Header: " End Odometer (" + displayDistance+")" ,
        accessor: "tripEndOdometer",
      },
      {
        Header: "Start Time",
        accessor: "tripStartTime",
        width:180,
      },
      {
        Header: "End Time",
        accessor: "tripEndTime",
        width:180,
      },
      {
        Header: "Distance Traveled (" + displayDistance +")" ,
        accessor: "distanceTravelled",
      },
      {
        Header: "Duration (hh:mm)",
        accessor: "tripDuration",
      },
      {
        Header: "Average/MaxSpeed ("+displayDistance+"/h)",
        accessor: "avgSpeedBymaxSpeed",
      },
      {
        Header: "Idle (hh:mm)",
        accessor: "idleminsinhrs",
      },
      {
        Header: "Moving (hh:mm)",
        accessor: "movminsinhrs",
      },
      {
        Header: "Trip Status",
        accessor: "tripStatus",
      },
      // {
      //   Header: "Driver Score",
      //   accessor: "driverScore",
      // },    
    ],
    []
  );

  
  // Get Data from New Dropdown
  const [getTripReport, { data: oversppedData, isLoading, isSuccess }] =
    useTripMutation();
  let commonPayload = {};
  const onSubmitForm = (data) => {
    setNewPage(0);
    setRowsPerPage(25);
    commonPayload.reselId = resellerId;
    commonPayload.regionId = data.regionId;
    commonPayload.dealerId = data.dealerId;
    commonPayload.custId = data.customerId;
    commonPayload.orgId = data.fleetId;
    commonPayload.vehId = data.vehicleId;
    commonPayload.search = "";
    commonPayload.startDate = data.fromDate;
    commonPayload.endDate = data.toDate;
    commonPayload.sortBy = "desc";
    commonPayload.page = newPage;
    commonPayload.size = rowsPerPage;
    //Service Call
    setPayload(commonPayload);
    getTripReport(commonPayload);

    auditPayload.message = "Reports > Trip Report > "+data.regionName+" > "+data.dealerName+" > "+data.customerName+" > "+data.fleetName+" > "+data.vehicleName+" > "+data.selectedPeriodName+"("+data.fromDate+"-"+data.toDate+")"+" > "+"Submit";
    auditPayload.action = "Submit";
    auditPayload.featureName = "Trip Report";
    auditPayload.customerName = "All";
    auditPayload.fleetName = "All";
    auditPayload.result = "Success";
    auditPayload.access = "Portal";
    auditPayload.severity = "INFO";
    auditPayload.userName = userName;
    auditLog(auditPayload);
  };
  //Export PDF & Excel
  const [payload, setPayload] = useState(null);
  const onChangeXcl = async () => {
    if(totalElements > 5000){
      navigate('#popup');
    }else{
    try {
      var url =
        process.env.REACT_APP_API_BASE_URL +
        "TripReport/tripReportCSV.csv?orgId=" +
        payload.orgId +
        "&regionId=" +
        payload.regionId +
        "&reselId=" +
        payload.reselId +
        "&vehId=" +
        payload.vehId +
        "&startDate=" +
        payload.startDate +
        "&sortBy=" +
        payload.sortBy +
        "&endDate=" +
        payload.endDate +
        "&search=" +
        payload.search +
        "&dealerId=" +
        payload.dealerId +
        "&custId=" +
        payload.custId + 
        "&displayDistance=" +
        displayDistance;

      window.open(url);

      auditPayload.message = "Reports > Trip Report > XLS";
      auditPayload.action = "View";
      auditPayload.featureName = "TripReport";
      auditPayload.customerName = "All";
      auditPayload.fleetName = "All";
      auditPayload.result = "Success";
      auditPayload.access = "Portal";
      auditPayload.severity = "INFO";
      auditLog(auditPayload);
    } catch (error) {}
  }
  };
  const onChangePDF = async () => {
    try {
      var url =
        process.env.REACT_APP_API_BASE_URL +
        "TripReport/tripreportPDF.pdf?orgId=" +
        payload.orgId +
        "&regionId=" +
        payload.regionId +
        "&reselId=" +
        payload.reselId +
        "&vehId=" +
        payload.vehId +
        "&startDate=" +
        payload.startDate +
        "&sortBy=" +
        payload.sortBy +
        "&endDate=" +
        payload.endDate +
        "&search=" +
        payload.search +
        "&dealerId=" +
        payload.dealerId +
        "&custId=" +
        payload.custId;

      window.open(url);

      auditPayload.message = "Reports > Trip Report >PDF";
      auditPayload.action = "View";
      auditPayload.featureName = "TripReport";
      auditPayload.customerName = "All";
      auditPayload.fleetName = "All";
      auditPayload.result = "Success";
      auditPayload.access = "Portal";
      auditPayload.severity = "INFO";
      auditLog(auditPayload);
    } catch (error) {}
  };
  //Search Text
  const handleSearch = async (searchText) => {
      setNewPage(0);
      setRowsPerPage(25);
      payload.page = 0;
      payload.rowsPerPage = 25;
      payload.search=searchText;
    if (payload.search.length >= 3) {
      getTripReport(payload);
    } else if (payload.search.length === 0) {
      getTripReport(payload);
    }
  };
  let initialState1 = {};
  var arr = [];

  // if (resellerId !== 110224 ) {
  //   arr.push("minSpeedRange");

  //   initialState1.hiddenColumns = arr;
  // }
  //Page Change
  const pageChange = (newPage, rowsPerPage) => {
    setRowsPerPage(rowsPerPage);
    payload.size = rowsPerPage;
    if(totalElements < rowsPerPage * newPage){
      setNewPage(0)
      payload.page = 0;
      getTripReport(payload);
    }else{
      setNewPage(newPage);
      payload.page = newPage;
      getTripReport(payload);
    }
  };

  useEffect(() => {
    if (isSuccess) {
      setResponseData([]);
      if (oversppedData.data !== null) {
        setResponseData(oversppedData.data.content);
        setTotalElements(oversppedData.data.totalElements);
        setTotalPages(oversppedData.data.totalPages);
      }

    }
  }, [isSuccess]);

  return (
    <StyledPageLayout>
      <Breadcrumb
        mainDiv="Reports"
        subDiv="Trip Report"
        includePopup={true}
      ></Breadcrumb>
      <MouseOverPopover text={text} title={"Trip Report"} />
      <Box>
        {/* <ReportNewDropDown
          onSubmit={handleSubmit}
          isShowVeh={true}
          displayDate={true}
          allowDays={7}
        /> */}
        <FilterDropdown getData={onSubmitForm} thisMonth={false} currentMonth={false} status={false} allowDays={7} time={true} allowedMonth = {3} last6Month={false} last31={false} getDataLoading={isLoading} offToday={true}/>
        <StyledCardShadow sx={{ mt: 2 }}>
          <CardContent sx={{ py: 1 }}>
            <DataTable
              isLoading={isLoading}
              // initialState={initialState1}
              columns={columns}
              data={responseData}
              onChangeXcl={onChangeXcl}
              onChangePdf={onChangePDF}
              styles={{ maxWidth: "100vw" }}
              numberOfStickyColumns={1}
              includeSearchBox={true}
              handleChange={handleSearch}
              page={newPage}
              rowsPerPage={rowsPerPage}
              callbackChangePage={pageChange}
              totalElements={totalElements}
              totalPages={totalPages}
            />
          </CardContent>
        </StyledCardShadow>
      </Box>
      <FormDialog title={'Alert'} size='sm'>
      <Typography>Exceeds limit , Use Filters Criteria</Typography>
      </FormDialog>
    </StyledPageLayout>
  );
};

export default TripReport;
