import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { snackService } from "../../store/slice/ControlsSlice";
import { StyledPageLayout } from "../../theme/StyledEle";
import { Breadcrumb } from "rsuite";
import StyledSnackBar from "../../Utils/StyledSnackBar";
import StyledTabs from "../shared/StyledTabs";
import ListServicePlans from "./UpcomingService";
import CreateServiceForm from "../servicePlan/CreateServiceForm";
import { useLocation, useNavigate } from "react-router-dom";
import FormDialog from "../../Utils/FormDialog";
import { useUpdateServicePlanMutation } from "../../store/slice/ApiSlice";
import ListServiceHistory from "./ServiceHistory";
import ListServiceRemainder from "./ListRemainder";

const ServicePlan = () => {

  const [tabShow, setTabShow] = React.useState("2");         
  const [create, setCreate] = useState("Create Service Plan");  
  const [commonFilter, setCommonFilter] = useState({ create: false,
    edit: false,
    view: false,
    delete: false,
    recentActivation: false,
    importfunction: false,
    excel: false,
    pdf: false,});   
  const [tempcommonFilter, setTempcommonFilter] = useState({ create: false,
    edit: false,
    view: false,
    delete: false,
    recentActivation: false,
    importfunction: false,
    excel: false,
    pdf: false,}); 
  const [menuUser, setMenuUser] = useState([]); 
  const navigate = useNavigate();
  const location = useLocation();
  const [serviceData, setServiceData] = useState();
  const [updateServicePlan, {data:updateServiceData, isLoading:updateServiceLoading, isSuccess:updateServiceSuccess}] = useUpdateServicePlanMutation();
      const isSuccess = useSelector((state) => state.controls.alert);
      const dispatch = useDispatch();
      useEffect(() => {
        if (isSuccess.isSuccess) {
          setTimeout(() => dispatch(snackService([null, null, null])), 6000);
        }
      }, [isSuccess.isSuccess]);
      const handleCancel = () => {
          setCreate("Create Service Plan");
        
          // No need to check conditions, always show Tab 1
          setTabShow("1");
        
          // Set the default tab value (Tab 1 is likely index 0)
          setTabValue(0);
        };


        const handleServiceEdit = (servicedata) => {
          console.log(servicedata)
          navigate("#edit");
          setServiceData(servicedata);
        };

        const handleOrgView = () => {
          setServiceData()
        };
        
  
      const TABS = [
        {
          label: "Reminder",
           children: <ListServiceRemainder/>,
        },
        {
          label: "Service History",
           children: <ListServiceHistory/>,
        },
          {
            label: "Services",
            children: <ListServicePlans onEditService={handleServiceEdit} onViewOrg={handleOrgView} menuUser={menuUser} />,
          },

        ];
  
        const callfun = () => {
          if (
            "null" !== JSON.parse(localStorage.getItem("menuList")) &&
            null !== JSON.parse(localStorage.getItem("menuList"))
          ) {
            if (JSON.parse(localStorage.getItem("menuList")) !== null) {
              for (
                let i = 0;
                i < JSON.parse(localStorage.getItem("menuList")).length;
                i++
              ) {
                if (JSON.parse(localStorage.getItem("menuList"))[i].sub.length > 0) {
                  for (
                    let j = 0;
                    j < JSON.parse(localStorage.getItem("menuList"))[i].sub.length;
                    j++
                  ) {
                    if (
                      JSON.parse(localStorage.getItem("menuList"))[i].sub[j].route ===
                      "device"
                    ) {
                      setMenuUser(
                        JSON.parse(localStorage.getItem("menuList"))[i].sub[j].button
                      );
                      var dataStr = {};
                      dataStr.create = false;
                      dataStr.edit = false;
                      dataStr.delete = false;
                      dataStr.view = false;
                      dataStr.recentActivation = false;
                      dataStr.importfunction = false;
                      dataStr.excel = false;
                      dataStr.pdf = false;
                      var strval;
                      strval = JSON.parse(localStorage.getItem("menuList"))[i].sub[
                        j
                      ].button.split(",");
                      var edit = false;
                      for (var k = 0; k < strval.length; k++) {
                        if (parseInt(strval[k]) === 1) {
                          dataStr.create = true;
                        }
                        if (parseInt(strval[k]) === 2) {
                          dataStr.view = true;
                        }
                        if (parseInt(strval[k]) === 4) {
                          dataStr.edit = true;
                        }
                        if (parseInt(strval[k]) === 3) {
                          dataStr.delete = true;
                        }
                        if (parseInt(strval[k]) === 18) {
                          dataStr.recentActivation = true;
                        }
                        if (parseInt(strval[k]) === 9) {
                          dataStr.importfunction = true;
                        }
                        if (parseInt(strval[k]) === 5) {
                          dataStr.excel = true;
                        }
                        if (parseInt(strval[k]) === 6) {
                          dataStr.pdf = true;
                        }
                      }
                      setCommonFilter(dataStr);
                      setTempcommonFilter(dataStr);
                      break;
                    }
                  }
                }
              }
            }
          }
      
          var displaydefaulttab = 2;
          setTabShow("2");
          return displaydefaulttab;
        };
  
        const [tabValue, setTabValue] = useState(() => callfun()); 
        
        const handleTabChange = (event, newValue) => {
          //setServiceToEdit(null);
          setTabValue(newValue);
          setCreate("Create Service Plan");
        };
        return (
          <StyledPageLayout>
            {/* <Breadcrumb mainDiv="Admin" subDiv="Service Plan"></Breadcrumb> */}
            
              <StyledTabs
                tabs={TABS}
                handleChange={handleTabChange}
                value={tabValue}
              />
                {isSuccess.isSuccess && (
              <StyledSnackBar message={isSuccess.message} status={isSuccess.status} />
            )}

              </StyledPageLayout>
        )
          
        
      
  }

  export default ServicePlan;