import { CardContent,Typography } from "@mui/material";
import { useTheme } from "@mui/styles";
import { Box } from "@mui/system";
import React, { useEffect, useMemo, useState } from "react";
import {
  useAuditLogMutation,
  useGeoFenceMutation,
} from "../../store/slice/ApiSlice";
import {
  StyledCardShadow,
  StyledPageLayout
} from "../../theme/StyledEle";
import FilterDropdown from "../NewDropdown/FilterDropdown";
import { Breadcrumb } from "../shared/Breadcrumbs";
import { DataTable } from "../shared/data_table/DataTable";
import MouseOverPopover from "../shared/PopOver";
import GetLocation from "../Location";

const text =
  "This report captures the vehicle details whenever it comes " +
  '"in & out"' +
  " of the assigned GeoZone.";

const GeoFenceReport = () => {
  let userData = JSON.parse(localStorage.getItem("userData"));
  let resellerId = userData.resellerId;
  let orgId = userData.orgId;
  let userId = userData.id;
  let orgName = userData.orgName;
  let userName = userData.userName;
  let distanceUnit = userData.distanceDisplayUnit ;
 // let distanceUnit = "mi" ;


  const [responseData, setResponseData] = useState([]);
  const [newPage, setNewPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [totalElements, setTotalElements] = useState(5);
  const [totalPages, setTotalPages] = useState(1);
  const theme = useTheme();
  const [auditLog] = useAuditLogMutation();
  let auditPayload = {
    //Audit Common Payload
    orgId: orgId,
    userId: userId, //User ID
    orgName: orgName, //Org Name
    userName: userName,
  };
  useEffect(async () => {
    auditPayload.message = "Reports > GeoZone";
    auditPayload.action = "View";
    auditPayload.featureName = "Geozone In/Out";
    auditPayload.customerName = "All";
    auditPayload.fleetName = "All";
    auditPayload.result = "Success";
    auditPayload.access = "Portal";
    auditPayload.severity = "INFO";
    auditLog(auditPayload);
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: "Date",
        accessor: "createdDate",
      },
      {
        Header: "Vehicle No",
        accessor: "vehicleno",
      },
      // {
      //   Header: "Customer Name",
      //   accessor: "customerName",
      //   width:120,
      // },
      {
        Header: "Zone Name",
        accessor: "geoZone",
        // Cell: ({ row }) => {
        //   return(
        //     row.original.zoneInLatLng !== "" &&   row.original.zoneInLatLng !== "NA" ?
        //   <Typography
        //   component={'a'}
        //     href={`https://www.google.com/maps/search/?api=1&query=${ row.original.zoneInLatLng}`}
        //     target="_blank"
        //     className="address"
           
        //     id={row.original.geoZone}
        //     key={row.original.geoZone}
        //     sx={{color:theme.palette.text.primary}}
          
        //   >{row.original.geoZone}</Typography> : "-"

        //   )
        // }
        Cell: ({ row, column, cell }) => {
         
          return (  row.original.zoneInLatLng !== "" &&   row.original.zoneInLatLng !== "NA" &&  row.original.zoneInLatLng !== "-"?
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Box
                className="addresslink"
                id={row.original.endGeozone}
                sx={{ display: "flex", alignItems: "center" }}
              >

                <a
                  href={`https://www.google.com/maps/search/?api=1&query=${row.original.zoneInLatLng}`}
                  target="_blank"
              
                  id={row.original.geoZone}
                >
                  {row.original.geoZone}
                </a>
           
              </Box>
            </Box> : row.original.geoZone
          );
        },
      },
      // {
      //   Header: "Zone Category",
      //   accessor: "zoneCategory",
      // },
      {
        Header: "Zone-In-Date/Time",
        accessor: "inDateTime",
      },
      {
        Header: "Zone-Out-Date/Time",
        accessor: "outDateTime",
      },
      {
        Header: "Zone-In Odometer (" + distanceUnit +")",
        accessor: "startOdometer",
      },
      {
        Header: "Zone-Out Odometer (" + distanceUnit+")",
        accessor: "endOdometer",
      },
      // {
      //   Header: "Fuel Level",
      //   accessor: "fuelLevel",
      // }
      // {
      //   Header: "Zone-In SOC (%)",
      //   accessor: "startSOC",
      // },
      // {
      //   Header: "Zone-Out SOC (%)",
      //   accessor: "endSOC",
      // },
    ],
    []
  );
  let initialState1 = {};
  var arr = [];

  if (resellerId !== 110224) {
 
    arr.push("customerName");
    arr.push("zoneCategory");
    initialState1.hiddenColumns = arr;
  }
  // const setloadvalues = () => {
  //   switch (globalOrgType) {
  //     case 8: // login as superadmin and reseller
  //     case 2:
  //       loadrequest.regionId = globalResellerId===113802? 1:-1;;
  //       loadrequest.reselId = globalOrgId;
  //       loadrequest.custId = -1;
  //       loadrequest.dealerId = globalResellerId===113802? 113806:-1;
  //       loadrequest.orgId = -1; // fleet
  //       loadrequest.vehicleId = -1;

  //       break;
  //     // case 2: // login as reseller
  //     //     loadrequest.regionId= -1;
  //     //     loadrequest.reselId = globalOrgId;
  //     //     loadrequest.dealerId= -1;
  //     //     loadrequest.customerId = -1;
  //     //     loadrequest.dealerId= -1;
  //     //     loadrequest.orgId = -1; // fleet
  //     //     loadrequest.vehicleId = -1;
  //     //      break;

  //     case 5: // login as Dealer
  //       loadrequest.regionId = 0;
  //       loadrequest.dealerId = globalOrgId;
  //       loadrequest.custId = -1;
  //       loadrequest.orgId = -1; // fleet
  //       loadrequest.vehicleId = -1;

  //       break;
  //     case 3: // login as customer
  //       loadrequest.regionId = 0;
  //       loadrequest.custId = globalOrgId;
  //       loadrequest.dealerId = 0;
  //       loadrequest.orgId = -1; // fleet
  //       loadrequest.vehicleId = -1;

  //       break;
  //     case 1: // login as fleet
  //       loadrequest.regionId = 0;
  //       loadrequest.custId = globalCustomerId;
  //       loadrequest.dealerId = 0;
  //       loadrequest.orgId = globalOrgId; // fleet
  //       loadrequest.vehicleId = -1;

  //       break;
  //   }
  // };

  // const fetchGeoFenceReportDetails = async () => {
  //   setloadvalues();
  //   loadrequest.length = rowsPerPage;
  //   loadrequest.start = newPage;
  //   const fromDate =
  //     format(addDays(new Date(), -1), "yyyy-MM-dd") + " 00:00:00";
  //   const toDate = format(addDays(new Date(), -1), "yyyy-MM-dd") + " 23:59:59";

  //   loadrequest.search = "";
  //   loadrequest.startDate = fromDate;
  //   loadrequest.endDate = toDate;
  //   loadrequest.orderDir = "desc";

  //   setAssignedval(loadrequest);

  //const responseData = (await ReportServices.GeoFenceReport(loadrequest))
  //     .data;
  //   setResponseData(responseData.data.content);
  //   setLoading(responseData.resultText);
  //   setTotalElements(5);
  //   setTotalPages(0);
  //   setTotalElements(responseData.data.totalElements);
  //   setTotalPages(responseData.data.totalPages);
  // };
  // const callbackChangePage = async (newPage, rowsPerPage) => {
  //   setNewPage(newPage);
  //   setRowsPerPage(rowsPerPage);
  //   // var request = {};
  //   // request.orgId = 1;
  //   // request.vehicleId = 25909;
  //   loadrequest = assignedval;
  //   loadrequest.length = rowsPerPage;
  //   loadrequest.start = newPage;

  //   setAssignedval(loadrequest);
  //   const responseData = (await ReportServices.GeoFenceReport(loadrequest))
  //     .data;
  //   setResponseData([]);
  //   setResponseData(responseData.data.content);
  //   setLoading(responseData.resultText);
  //   setTotalElements(5);
  //   setTotalPages(0);
  //   setTotalElements(responseData.data.totalElements);
  //   setTotalPages(responseData.data.totalPages);
  // };

  // const onChangeXcl = async () => {
  //   loadrequest = assignedval;

  //   loadrequest.length = rowsPerPage;
  //   loadrequest.start = newPage;

  //   try {
  //     var url =
  //       process.env.REACT_APP_API_BASE_URL +
  //       "GeoFenceReport/GeoFenceXLS.xls?startDate=" +
  //       loadrequest.startDate +
  //       "&endDate=" +
  //       loadrequest.endDate +
  //       "&search=" +
  //       loadrequest.search +
  //       "&orderDir=" +
  //       loadrequest.orderDir +
  //       "&orgId=" +
  //       loadrequest.orgId +
  //       "&start=" +
  //       loadrequest.start +
  //       "&length=" +
  //       loadrequest.length +
  //       "&vehicleId=" +
  //       loadrequest.vehicleId +
  //       "&reselId=" +
  //       loadrequest.reselId +
  //       "&regionId=" +
  //       loadrequest.regionId +
  //       "&dealerId=" +
  //       loadrequest.dealerId +
  //       "&custId=" +
  //       loadrequest.custId;
  //     window.open(url);

  //     var request = {};
  //     request.message = "Reports > XLS";
  //     request.action = "View";
  //     request.featureName = "Geo Zone";
  //     request.customerName = "All";
  //     request.fleetName = "All";
  //     request.result = "Success";
  //     request.access = "Portal";
  //     request.severity = "INFO";
  //     request.orgId = globalOrgId;
  //     request.userId = globaluserId;
  //     request.orgName = globalOrgName;
  //     request.userName= globalUserName;

  //     const responseData = await logServices.saveAuditLogDetails(request);

  //   } catch (error) {}
  // };

  // const onChangePDF = async () => {
  //   loadrequest = assignedval;

  //   loadrequest.length = rowsPerPage;
  //   loadrequest.start = newPage;

  //   try {
  //     var url =
  //       process.env.REACT_APP_API_BASE_URL +
  //       "GeoFenceReport/GeoFencePDF.pdf?startDate=" +
  //       loadrequest.startDate +
  //       "&endDate=" +
  //       loadrequest.endDate +
  //       "&search=" +
  //       loadrequest.search +
  //       "&orderDir=" +
  //       loadrequest.orderDir +
  //       "&orgId=" +
  //       loadrequest.orgId +
  //       "&start=" +
  //       loadrequest.start +
  //       "&length=" +
  //       loadrequest.length +
  //       "&vehicleId=" +
  //       loadrequest.vehicleId +
  //       "&reselId=" +
  //       loadrequest.reselId +
  //       "&regionId=" +
  //       loadrequest.regionId +
  //       "&dealerId=" +
  //       loadrequest.dealerId +
  //       "&custId=" +
  //       loadrequest.custId;
  //     window.open(url);

  //     var request = {};
  //     request.message = "Reports > PDF";
  //     request.action = "View";
  //     request.featureName = "Geo Zone";
  //     request.customerName = "All";
  //     request.fleetName = "All";
  //     request.result = "Success";
  //     request.access = "Portal";
  //     request.severity = "INFO";
  //     request.orgId = globalOrgId;
  //     request.userId = globaluserId;
  //     request.orgName = globalOrgName;
  //     request.userName= globalUserName;

  //     const responseData = await logServices.saveAuditLogDetails(request);

  //   } catch (error) {}
  // };

  // const handleSearch = async (searchText) => {
  //   setSearchVal(searchText);
  //   loadrequest = assignedval;
  //   loadrequest.search = searchText;
  //   setAssignedval(loadrequest);
  //   const responseData = (await ReportServices.GeoFenceReport(loadrequest))
  //     .data;
  //   setResponseData(responseData.data.content);
  //   setLoading(responseData.resultText);
  // };

  // const handleSubmit = async (data) => {

  //   loadrequest.regionId = data.region;
  //   loadrequest.reselId = data.reseller;
  //   //loadrequest.resellerId = 5;
  //   loadrequest.dealerId = data.dealer;
  //   loadrequest.custId = data.customer;
  //   //loadrequest.dealerId= data.dealer;
  //   loadrequest.orgId = data.fleet; // fleet
  //   loadrequest.vehicleId = data.vehicle;
  //   loadrequest.length = rowsPerPage;
  //   loadrequest.start = newPage;
  //   loadrequest.startDate = data.from_date;
  //   loadrequest.endDate = data.to_date;
  //   loadrequest.orderDir = "desc";
  //   // loadrequest.search = "";
  //   loadrequest.search = searchval;
  //   setAssignedval(loadrequest);
  //   const responseData = (await ReportServices.GeoFenceReport(loadrequest))
  //     .data;
  //   setResponseData(responseData.data.content);
  //   setLoading(responseData.resultText);
  //   setTotalElements(5);
  //   setTotalPages(0);
  //   setTotalElements(responseData.data.totalElements);
  //   setTotalPages(responseData.data.totalPages);
  //};

  // Get Data from New Dropdown
  const [getOverSpeed, { data: oversppedData, isLoading, isSuccess }] =
    useGeoFenceMutation();
  let commonPayload = {};
  const onSubmitForm = (data) => {
    setNewPage(0);
    setRowsPerPage(25);
    commonPayload.reselId = resellerId;
    commonPayload.regionId = data.regionId;
    commonPayload.dealerId = data.dealerId;
    commonPayload.custId = data.customerId;
    commonPayload.orgId = data.fleetId;
    commonPayload.vehicleId = data.vehicleId;
    commonPayload.search = "";
    commonPayload.startDate = data.fromDate;
    commonPayload.endDate = data.toDate;
    commonPayload.orderDir = "desc";
    commonPayload.start = newPage;
    commonPayload.length = rowsPerPage;
    commonPayload.size = rowsPerPage;
    commonPayload.zoneCategory = data.zoneCategory;
    //commonPayload.distanceUnit = distanceUnit;
    //Service Call
    setPayload(commonPayload);
    getOverSpeed(commonPayload);

    auditPayload.message = "Reports > Geozone In/Out > "+data.regionName+" > "+data.dealerName+" > "+data.customerName+" > "+data.fleetName+" > "+data.vehicleName+" > "+data.selectedPeriodName+"("+data.fromDate+"-"+data.toDate+")"+" > "+"Submit";
    auditPayload.action = "Submit";
    auditPayload.featureName = "GeoFenceReport";
    auditPayload.customerName = "All";
    auditPayload.fleetName = "All";
    auditPayload.result = "Success";
    auditPayload.access = "Portal";
    auditPayload.severity = "INFO";
    auditPayload.userName = userName;
    auditLog(auditPayload);
  };
  //Export PDF & Excel
  const [payload, setPayload] = useState(null);
  const onChangeXcl = async () => {
    try {
      var url =
        process.env.REACT_APP_API_BASE_URL +
        "GeoFenceReport/geofenceCSV.csv?startDate=" +
        payload.startDate +
        "&endDate=" +
        payload.endDate +
        "&search=" +
        payload.search +
        "&orderDir=" +
        payload.orderDir +
        "&orgId=" +
        payload.orgId +
        "&start=" +
        payload.start +
        "&length=" +
        payload.length +
        "&vehicleId=" +
        payload.vehicleId +
        "&reselId=" +
        payload.reselId +
        "&regionId=" +
        payload.regionId +
        "&dealerId=" +
        payload.dealerId +
        "&zoneCategory=" +
        payload.zoneCategory +
        "&custId=" +
        payload.custId +
        "&distanceUnit=" +
        distanceUnit ;

      window.open(url);

      auditPayload.message = "Reports > Geo Fence > XLS";
      auditPayload.action = "View";
      auditPayload.featureName = "Geozone In/Out";
      auditPayload.customerName = "All";
      auditPayload.fleetName = "All";
      auditPayload.result = "Success";
      auditPayload.access = "Portal";
      auditPayload.severity = "INFO";
      auditLog(auditPayload);

      fetch(url, {
        headers: {
          Authorization:
            "Bearer " + JSON.parse(window.localStorage.getItem("jwToken")),
        },
      }) // FETCH BLOB FROM IT
        .then((response) => {
          response.blob();
        })
        .then((blob) => {})
        .catch((err) => {});
    } catch (error) {}
  };
  const onChangePDF = async () => {
    try {
      var url =
        process.env.REACT_APP_API_BASE_URL +
        "GeoFenceReport/GeoFencePDF.pdf?startDate=" +
        payload.startDate +
        "&endDate=" +
        payload.endDate +
        "&search=" +
        payload.search +
        "&orderDir=" +
        payload.orderDir +
        "&orgId=" +
        payload.orgId +
        "&start=" +
        payload.start +
        "&length=" +
        payload.length +
        "&vehicleId=" +
        payload.vehicleId +
        "&reselId=" +
        payload.reselId +
        "&regionId=" +
        payload.regionId +
        "&dealerId=" +
        payload.dealerId +
        "&custId=" +
        payload.custId;
      window.open(url);

      auditPayload.message = "Reports > Geo Fence > PDF";
      auditPayload.action = "View";
      auditPayload.featureName = "Geozone In/Out";
      auditPayload.customerName = "All";
      auditPayload.fleetName = "All";
      auditPayload.result = "Success";
      auditPayload.access = "Portal";
      auditPayload.severity = "INFO";
      auditLog(auditPayload);
      //  var request = {};
      //  request.message = "Reports > OverSpeed > PDF";
      //  request.action = "View";
      //  request.featureName = "OverSpeed";
      //  request.customerName = "All";
      //  request.fleetName = "All";
      //  request.result = "Success";
      //  request.access = "Portal";
      //  request.severity = "INFO";
      //  request.orgId = globalOrgId;
      //  request.userId = globaluserId;
      //  request.orgName = orgName;
      //  request.userName = userName;

      //  const responseData = await logServices.saveAuditLogDetails(request);
    } catch (error) {}
  };
  //Search Text
  const handleSearch = async (searchText) => {
    payload.search = searchText;
    setNewPage(0);
    setRowsPerPage(25);
    payload.page = 0;
    payload.rowsPerPage = 25;
    if (payload.search.length >= 3) {
      getOverSpeed(payload);
    } else if (payload.search.length === 0) {
      getOverSpeed(payload);
    }
  };
  //Page Change
  const pageChange = (newPage, rowsPerPage) => {
    setRowsPerPage(rowsPerPage);
    payload.size = rowsPerPage;
    if(totalElements < rowsPerPage * newPage){
      setNewPage(0)
      payload.page = 0;
      getOverSpeed(payload);
    }else{
      setNewPage(newPage);
      payload.page = newPage;
      getOverSpeed(payload);
    }
  };



  useEffect(() => {
    if (isSuccess) {
      setResponseData([]);
      if (oversppedData.data !== null) {
        setResponseData(oversppedData.data.content);
        setTotalElements(oversppedData.data.totalElements);
        setTotalPages(oversppedData.data.totalPages);
      }
    }
  }, [isSuccess]);

  return (
    <StyledPageLayout>
      <Breadcrumb
        mainDiv="Reports"
        subDiv="Geo Zone In/Out Detail"
        includePopup={true}
      ></Breadcrumb>
      <MouseOverPopover text={text} title={"Geo Zone In/Out Detail"} />
      <Box>
        {/* <ReportNewDropDown
          onSubmit={handleSubmit}
          isShowVeh={true}
          displayDate={true}
          allowDays={30}
        /> */}
        <FilterDropdown getData={onSubmitForm} status={false} allowDays = {7}
        time = {true} allowedMonth = {3}  zoneCategoryShow={false} last31={false} getDataLoading={isLoading}  offToday={true}  />
        <StyledCardShadow sx={{ mt: 2 }}>
          <CardContent sx={{ py: 1 }}>
            <DataTable
              initialState={initialState1}
              columns={columns}
              payload={payload}
              exportWithAll={true}
              includeSearchBox={true}
              onChangeXcl={onChangeXcl}
              onChangePdf={onChangePDF}
              handleChange={handleSearch}
              data={responseData}
              styles={{ maxWidth: "100vw" }}
              numberOfStickyColumns={1}
              page={newPage}
              rowsPerPage={rowsPerPage}
              callbackChangePage={pageChange}
              totalElements={totalElements}
              totalPages={totalPages}
              isLoading={isLoading}
            />
          </CardContent>
        </StyledCardShadow>
      </Box>
    </StyledPageLayout>
  );
};

export default GeoFenceReport;
