import { Delete } from "@mui/icons-material";
import {
  Box,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  Checkbox,
  Collapse,
  Divider,
  Fab,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  ListSubheader,
  MenuItem,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
  useTheme
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { PulseLoader } from "react-spinners";
import dropdownServices from "../../services/dropdownServices";
import OrganizationServices from "../../services/organizationServices";
import userServices from "../../services/userServices";
import {
  useAuditLogMutation,
  useCheckServiceExistMutation,
  useEditServicePlanMutation,
  useGetUserMutation,
  useGetVehicleDropdownListMutation,
  useSaveServicePlanMutation,
  useSaveUserMutation,
  useUpdateUserMutation,
} from "../../store/slice/ApiSlice";
import { snackService } from "../../store/slice/ControlsSlice";
import {
  FileUploadBox,
  SoftButton,
  StyledCardShadow,
  StyledDropDown,
  StyledFormButtonsRow,
  StyledFormControl,
  StyledFormControlLabel,
  StyledTableTitle,
  StyledTextField,
} from "../../theme/StyledEle";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import moment from "moment";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import { useLocation, useNavigate } from "react-router-dom";
import ServicePlanService from "../../services/servicePlanService";

function EditServices({ reload, editData, isEdit }) {

  const re = /^[0-9\b]+$/;
  let userData = JSON.parse(localStorage.getItem("userData"));
  let resellerId = userData.resellerId;
  let orgId = userData.orgId;
  let userId = userData.id;
  let orgName = userData.orgName;
  let userName = userData.userName;
  let roleId = userData.roleId;
  let userOrgType = userData.userOrgType;

  const theme = useTheme();

  const [auditLog] = useAuditLogMutation();
  let auditPayload = {
    //Audit Common Payload
    orgId: orgId,
    userId: userId, //User ID
    orgName: orgName, //Org Name
    userName: userName,
  };

  // For Service Planner -- to load customer ,fleet for first time

  const [country, setCountry] = useState([]);
  const [roles, setRoles] = useState([]);
  const [commonDropDownSelData, setCommonDropDownSelData] = useState({});
  const [clickedval, setClickedval] = React.useState(false);
  const [dealers, setDealers] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [fleets, setFleets] = useState([]);
  const [vehicle, setVehicle] = useState([]);
  const [vehicles, setVehicles] = useState([]);
  const [organization, setOrganization] = useState([]);
  const [orgType, setOrgType] = useState(userOrgType === 8 ? 8 : 2);
  const [org, setOrg] = useState(0);
  const [dealer, setDealer] = useState(0);
  const [customer, setCustomer] = useState(0);
  const [fleet, setFleet] = useState(0);
  const [emailId, setEmailId] = useState(null);
  const [isRecurring, setIsRecurring] = useState(false);
  const [date, setDate] = useState(moment(new Date()).format("YYYY-MM-DD"));
  const [reminderDuration, setRemainderDuration]  = useState([]);
  const [isTimeBased, setIsTimeBased] = useState(false);
  const [isDistanceBased, setIsDistanceBased] = useState(false);
  const [distanceRange, setDistanceRange] = useState();
  const [remainderDistance , setRemainderDistance] = useState();
  const [recurenceType, setRecurrenceType] = useState(1);
  const [selectedMonth, setSelectedMonth] = useState("1");
  const [nextServiceDistance , setNextServiceDistance] = useState();
  const [currentOdometer, setCurrentOdometer] = useState();

  let url = window.location.origin;

  const {
    register,
    setValue,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm({
    mode: "all",
    defaultValues: {
      roleId: '0',
    }
  });

  const serviceType = [
    { title: "General", value: 1 },
    { title: "Repair", value: 2 },
    { title: "Oil Change", value: 3 }] ;

    const serviceT = { title: "Repair", value: 2 } ;



    useEffect(() => {
        if (editData != null && isEdit != undefined) {
          let recurrenceValue = true;
          recurrenceValue  = editData.recurrenceType == 2 ?true:false;
          setIsRecurring(recurrenceValue);
          setRecurrenceType(recurrenceValue ? 2:1);
          setDate(editData.serviceDate);
          setValue("serviceType",editData.serviceType);

          if (editData.reminderList != null && editData.reminderList != "") {
            setRemainderDuration(editData?.reminderList?.split(',').map(Number));
          }
          editData.recurrenceType == 2 && editData.serviceDate !== "NA" ? setIsTimeBased(true) : setIsTimeBased(false);
          editData.recurrenceType == 2 && editData.recurrenceDistance != "NA" ? setIsDistanceBased(true) : setIsDistanceBased(false);
          editData.recurrenceDuration != 0 ? setSelectedMonth(editData.recurrenceDuration) : setSelectedMonth(0);
          if (editData.recurrenceDistance != "NA") {
            setDistanceRange(editData.recurrenceDistance);
            setNextServiceDistance(editData?.nextServiceDistance);
          }

          if (editData.reminderDistance !== "NA") {
            setRemainderDistance(editData?.reminderDistance);
          }
          
        }

    },[editData]);

    useEffect(() => {
    },[reminderDuration]);


    useEffect( () => {
      getCurrentOdometer();
    },[vehicle]);


  useEffect(async () => {

    auditPayload.message = "Admin > Service Planner > Create Service Plan";
    auditPayload.action = "View";
    auditPayload.featureName = "Service Planner";
    auditPayload.customerName = "All";
    auditPayload.fleetName = "All";
    auditPayload.result = "Success";
    auditPayload.access = "Portal";
    auditPayload.severity = "INFO";
    auditPayload.userName = userName;
    auditLog(auditPayload);
    console.log(userOrgType);
      
    
      getCustomer();
  }, []);


  const getCurrentOdometer = async () => {

    const currentOdometerResponse = await ServicePlanService.getCurrentOdometer(vehicle);
    const currentOdometerReading = currentOdometerResponse.data.data.currentOdometer;

    setCurrentOdometer(currentOdometerReading);
  }

  const dealerListPayload = {
    value: "dealer",
    resellerId: resellerId,
    searchText: "",
    regionId: -1,
  };

  const getDealer = async () => {
    const { data: dealerListResponse } = await dropdownServices.getResellers(
      dealerListPayload
    );
    const { content: dealerList } = dealerListResponse.data;
    setDealers(dealerList);
    if (!search) {
      setDealer(dealerList.length > 0 ? dealerList[0].key : 0);
    }
    getCustomer(dealerList.length > 0 ? dealerList[0].key : 0);
  };

  const customersListPayload = {
    value: "customer",
    resellerId: resellerId,
    searchText: "",
    dealerId: 0,
  };

  const getCustomer = async (dealerId) => {
    //customersListPayload.dealerId= dealerId;
    const { data: customerListResponse } = await dropdownServices.getResellers(
      customersListPayload
    );
    const { content: customersList } = customerListResponse.data;
    setCustomers(customersList);
    if (!search) {
      setCustomer(customersList.length > 0 ? customersList[0].key : 0);
    }
    setSearch(false);
    getFleet(customersList.length > 0 ? customersList[0].key : 0);
  };

  //Feelt

  const fleetListPayload = {
    value: "fleet",
    dealerId: "0",
    resellerId: resellerId,
    searchText: "",
    customerId:orgId
  };

  const getFleet = async (customerId) => {
    
    fleetListPayload.customerId = customerId;
    const { data: fleetListResponse } = await dropdownServices.getResellers(
      fleetListPayload
    );
    const { content: fleetList } = fleetListResponse.data;
    setFleets(fleetList);
    if (!search) {
      setFleet(fleetList.length > 0 ? fleetList[0].key : 0);
    }
    setSearch(false);
    getVehicle(fleetList.length > 0 ? fleetList[0].key : 0)
  }


  let vehicleListPayload = {
    value: "orgId",
    searchText: ""
  };
  const getVehicle = async (fleetId) => {

    if (fleetId !== "-1" && fleetId !== 404) {
      vehicleListPayload.paramValue = fleetId;
      if (search) {
        vehicleListPayload.headerValue = vehicle;
        vehicleListPayload.param = 'search';
      }
      const { data: VehicleListResponse } = await dropdownServices.getFleetList(
        vehicleListPayload
      );

      const { content: VehicleList } = VehicleListResponse.data;
      setVehicles(VehicleList);
      if (!search) {
        setVehicle(VehicleList.length > 0 ? VehicleList[0].key : 0);
      }
      setSearch(false);
    } else {
      setVehicle("-1");
    }
  }

  // API Services
  const [
    editServicePlan,
    { data: saveData, isLoading: isSaveLoading, isSuccess: isSaveSuccess },
  ] = useEditServicePlanMutation();

  const [
    checkExistingService,
    { data: checkService, isLoading: isCheckLoading, isSuccess: isCheckSuccess },
  ] = useCheckServiceExistMutation();


  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const onSubmit = (data) => {

    // if(date == null || date == undefined) {
    //   dispatch(snackService(['Service Date Required', 1, true]));
    // }
    // else{
    //   saveServicePlan(data);
    // }
    updateServicePlan(data);
  };

  const handleCancelClick = () => {
    navigate(location.pathname)
    
  }

  const updateServicePlan = async (obj) => {

    obj.id = editData.id;
    obj.createdBy = userId;
    obj.orgId = orgId;
    obj.resellerId = resellerId;
    obj.customerId = customer;
    obj.fleetId = fleet;
    obj.vehicleId = vehicle;
    obj.recurrenceType = recurenceType;
    obj.action = "Create";
    if (!isRecurring) {
      obj.serviceDate = date;
      obj.reminderDurationList = reminderDuration?.join(',');
    }
    if (isRecurring && isDistanceBased) {
      obj.recurrenceDistance = distanceRange;
      obj.reminderDistance = remainderDistance;
      obj.nextServiceDistance = nextServiceDistance;
    }
    if (isRecurring && isTimeBased) {
      obj.recurrenceDuration = selectedMonth;
      obj.serviceDate = date;
      obj.reminderDurationList = reminderDuration?.join(',');
    }
    editServicePlan(obj);
  };


  useEffect(() => {
    if (isSaveSuccess) {
      dispatch(
        snackService([
          saveData?.resultMessage,
          saveData?.resultText,
          isSaveSuccess,
        ])
      );
      handleCancelClick()
      // reload(editData)
    }
  }, [isSaveSuccess]);



  // const usernameValidate = async(password) => {

  //     if(editUser){
  //       return  "";
  //     }
  //     else{
  //       var request = {};
  //       request.userName = password;
  //       request.resellerId = resellerId;

  //       const chkUserName = await userServices.chkUserName(
  //         request
  //       );

  //       return  chkUserName.data.resultMessage;
  //     }
  //   }
  let [search, setSearch] = useState(null);
  let [searchBy, setSearchBy] = useState(null);
  const searchOption = async (event, name) => {
    setSearchBy(name);
    setSearch(true);
    if (name === 'dealer') {
      dealerListPayload.searchText = event.target.value
      const { data: dealerListResponse } = await dropdownServices.getResellers(
        dealerListPayload
      );
      const { content: dealerList } = dealerListResponse.data;
      setDealers(dealerList);
    } else if (name === 'customer') {
      customersListPayload.dealerId = dealer;
      customersListPayload.searchText = event.target.value
      const { data: customerListResponse } = await dropdownServices.getResellers(
        customersListPayload
      );
      const { content: customersList } = customerListResponse.data;
      setCustomers(customersList);
    } else if (name === 'fleet') {
      customersListPayload.dealerId = 0;
      customersListPayload.customerId = customer;
      customersListPayload.searchText = event.target.value
      const { data: fleetListResponse } = await dropdownServices.getResellers(
        customersListPayload
      );
      const { content: fleetList } = fleetListResponse.data;
      setFleets(fleetList);
    }
  }

  useEffect(() => {
    if (search && searchBy === 'dealer') {
      setSearch(null);
      setSearchBy(null);
      getDealer();
    }
    else if (search && searchBy === 'customer') {
      setSearch(null);
      setSearchBy(null);
      getCustomer(dealer);
    } else if (search && searchBy === 'fleet') {
      setSearch(null);
      setSearchBy(null);
      getFleet(customer);
    }
  }, [dealer, customer])


  // if(!search){
  //   if(searchBy === 'dealer'){
  //     setSearchBy(null);
  //     //getDealer('');
  //   }
  // }

  const getExt = (filename) => {
    var ext = filename.name.split('.').pop();
    if (ext === 'jpg' || ext === 'png' || ext === 'pdf') {
      return "success"
    } else {
      dispatch(snackService(['Photo only allows file types of PNG, JPG and JPEG', 1, true]));
      return "failed"
    }

  }


  const handleDateChange = (newValue) => {
    setDate(moment(newValue).format("YYYY-MM-DD"));
  };

  const handleTimeBasedChange = (event) => setIsTimeBased(event.target.checked);
  const handleDistanceBasedChange = (event) => setIsDistanceBased(event.target.checked);
  const handleRemainderDurationChange = (event, days) => {
    if (event.target.checked) {
      
      setRemainderDuration((prev) => [...prev, days]);
    } else {
      setRemainderDuration((prev) => prev.filter((day) => day !== days));
    }
  }

  return (
    <Box>
      <form onSubmit={handleSubmit(onSubmit)}>
        <StyledCardShadow elevation={0} sx={{ mt: 2, my: 2 }}>
          <CardHeader
            title={<StyledTableTitle>{isEdit ?"Edit Service Plan" : "Create Service Plan"}</StyledTableTitle>}
          />
          <CardContent>
            <Grid container spacing={3}>
              {userOrgType !=3 &&<Grid item lg={4}>
                <StyledFormControl fullWidth>
                  <StyledFormControlLabel required>
                    Customer
                  </StyledFormControlLabel>
                  <StyledDropDown
                    size="small"
                    placeholder=""
                    value={isEdit != undefined ? editData?.customerId :customer}
                    onChange={(event, newCommond) => {
                      setCustomer(event.target.value)
                      getFleet(event.target.value)
                    }
                    }
                    MenuProps={{
                      MenuListProps: {
                        autoFocus: false,
                        autoFocusItem: false
                      }
                    }}
                    disabled
                  >
                    <ListSubheader>
                      <StyledTextField size="small" fullWidth onChange={(event) => searchOption(event, 'customer')} onKeyDown={(e) => e.stopPropagation()} />
                    </ListSubheader>
                    {customers.length > 0 &&
                      customers.map((option, index) => {
                        return (
                          <MenuItem key={index} value={option.key}>
                            {option.value}
                          </MenuItem>
                        );
                      })}
                  </StyledDropDown>
                </StyledFormControl>
              </Grid>}
              <Grid item lg={4}>
                <StyledFormControl fullWidth>
                  <StyledFormControlLabel required>
                    Fleet
                  </StyledFormControlLabel>
                  <StyledDropDown
                    size="small"
                    placeholder=""
                    value={isEdit != undefined ? editData?.fleetId :fleet}
                    onChange={(event, newCommond) => {
                      setFleet(event.target.value)
                      getVehicle(event.target.value)
                    }
                    }
                    MenuProps={{
                      MenuListProps: {
                        autoFocus: false,
                        autoFocusItem: false
                      }
                    }}
                    disabled
                  >
                    <ListSubheader>
                      <StyledTextField size="small" fullWidth onChange={(event) => searchOption(event, 'fleet')} onKeyDown={(e) => e.stopPropagation()} />
                    </ListSubheader>
                    {fleets.length > 0 &&
                      fleets.map((option, index) => {
                        return (
                          <MenuItem key={index} value={option.key}>
                            {option.value}
                          </MenuItem>
                        );
                      })}
                  </StyledDropDown>
                </StyledFormControl>
              </Grid>
              <Grid item lg={4}>
                <StyledFormControl fullWidth>
                  <StyledFormControlLabel required>
                    Vehicle
                  </StyledFormControlLabel>
                  <StyledDropDown
                    size="small"
                    placeholder=""
                    value={isEdit != undefined ? editData?.vehicleId :vehicle}
                    onChange={(event, newCommond) => {
                      setVehicle(event.target.value)

                    }
                    }
                    MenuProps={{
                      MenuListProps: {
                        autoFocus: false,
                        autoFocusItem: false
                      }
                    }}
                    disabled
                  >
                    <ListSubheader>
                      <StyledTextField size="small" fullWidth onChange={(event) => searchOption(event, 'customer')} onKeyDown={(e) => e.stopPropagation()} />
                    </ListSubheader>
                    {vehicles.length > 0 &&
                      vehicles.map((option, index) => {
                        return (
                          <MenuItem key={index} value={option.key}>
                            {option.value}
                          </MenuItem>
                        );
                      })}
                  </StyledDropDown>
                </StyledFormControl>
              </Grid>
              <Grid item lg={4}>
                <StyledFormControl fullWidth>
                  <StyledFormControlLabel required>
                    Service Type
                  </StyledFormControlLabel>
                  <StyledDropDown
                    error={errors.serviceType && true}
                    // {...register("serviceType", {
                    //   required: {
                    //     value: true,
                    //     message: "Required Field",
                    //   },
                    //   validate: (fieldValue) => {
                    //     return fieldValue !== "0" || " Please select Service Type";
                    //   },
                    // })}
                    onChange={(e) => {
                      setValue("serviceType", e.target.value);
                    }}
                    disabled
                    defaultValue={ isEdit != undefined ? editData?.serviceType:"1" }
                    size="small"
                                     >
                    <MenuItem value="0">Select</MenuItem>
                    {serviceType.length > 0 &&
                      serviceType.map((option, index) => {
                        return (
                          <MenuItem key={index} value={option.value}>
                            {option.title}
                          </MenuItem>
                        );
                      })}
                  </StyledDropDown>
                </StyledFormControl>
                <FormHelperText error={errors.serviceType && true}>
                    {errors.serviceType?.message}
                  </FormHelperText>
              </Grid>

            </Grid>
          </CardContent>
        </StyledCardShadow>
        <StyledCardShadow elevation={0} sx={{ mt: 2, my: 2 }}>
          <CardContent>
            <Grid container spacing={3}>
              <Grid item lg={6}>
                <StyledFormControl fullWidth>
                  <StyledFormControlLabel required>
                    Recurrence Type
                  </StyledFormControlLabel>
                  <RadioGroup row value={isRecurring ? "recurring" : "one-time"}
                    onChange={(event) => {
                      const value = event.target.value === "recurring";
                      setIsRecurring(value);
                      setRecurrenceType(value ? 2 : 1);
                    }}>
                    <FormControlLabel value="one-time" control={<Radio />} label="One-Time" />
                    <FormControlLabel value="recurring" control={<Radio />} label="Recurring" />
                  </RadioGroup>
                </StyledFormControl>
              </Grid>

              {!isRecurring && (
                <Grid item lg={8}>
                  <StyledFormControl sx={{width:"40%"}}>
                    <StyledFormControlLabel required>
                      Service date
                    </StyledFormControlLabel>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DesktopDatePicker
                        inputFormat="yyyy-MM-dd"
                        minDate={dayjs().add(1, "day")}
                        value={date}
                        onChange={handleDateChange}
                        renderInput={(params) => (
                          <StyledTextField
                            fullWidth
                            size="small"
                            {...params}
                            error={errors.serviceDate && true}
                            helperText={
                              errors.serviceDate && errors.serviceDate.message
                            }
                            // inputProps={{
                            //   ...params.inputProps,
                            //   placeholder: "Select Date",
                            // }}
                            disabled={clickedval}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </StyledFormControl>
                </Grid>
              )}


              {isRecurring && (


                <Grid item lg={12}>
                  <StyledFormControl fullWidth>
                    <StyledFormControlLabel required >
                      Recurrence Category
                    </StyledFormControlLabel>
                    <Stack direction="column" spacing={0}>
                    
                      <FormControlLabel 
                        control={<Checkbox checked={isTimeBased} onChange={handleTimeBasedChange} />}
                        label={"Duration"}
                      />

                      {isTimeBased && isRecurring && (
                        <CardContent sx={{ px: 3 }}>
                          <Grid container spacing={3}>
                            <Grid item lg={3}>
                              <StyledFormControl fullWidth>
                                <StyledFormControlLabel required>
                                  Service date
                                </StyledFormControlLabel>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                  <DesktopDatePicker
                                    inputFormat="yyyy-MM-dd"
                                    minDate={dayjs().add(1, "day")}
                                    value={date}
                                    onChange={handleDateChange}
                                    renderInput={(params) => (
                                      <StyledTextField
                                        fullWidth
                                        size="small"
                                        {...params}
                                        error={errors.serviceDate && true}
                                        helperText={
                                          errors.serviceDate && errors.serviceDate.message
                                        }
                                        // inputProps={{
                                        //   ...params.inputProps,
                                        //   placeholder: "Select Date",
                                        // }}
                                        disabled={clickedval}
                                      />
                                    )}
                                  />
                                </LocalizationProvider>
                              </StyledFormControl>
                            </Grid>
                            <Grid item xs={12}>
                              <StyledFormControl fullWidth>
                                <StyledFormControlLabel required>
                                  Recurrence Duration
                                </StyledFormControlLabel>
                              </StyledFormControl>
                              <RadioGroup aria-label="anonymous" name="anonymous" row
                                value={selectedMonth}
                                onChange={(event) => {
                                  const value = event.target.value;
                                  setSelectedMonth(value); // set the selected month value
                                }}
                              >

                                <FormControlLabel value="1" control={<Radio />} label="1 Month" size="small" />
                                <FormControlLabel value="3" control={<Radio />} label="3 Months" size="small" />
                                <FormControlLabel value="6" control={<Radio />} label="6 Months" size="small" />

                              </RadioGroup>
                            </Grid>
                            <Grid item lg={12}>
                              <StyledFormControl fullWidth>
                                <StyledFormControlLabel >
                                Notify me
                                </StyledFormControlLabel>
                                <Stack direction="row" spacing={0}>
                                  <FormControlLabel
                                    control={<Checkbox checked={reminderDuration?.includes(30)} onChange={(e)=>handleRemainderDurationChange(e, 30)} size="small" />}
                                    label="30 Days"
                                  />
                                  <FormControlLabel
                                    control={<Checkbox checked={reminderDuration?.includes(15)} onChange={(e)=>handleRemainderDurationChange(e, 15)} size="small" />}
                                    label="15 Days"
                                  />
                                  <FormControlLabel
                                    control={<Checkbox checked={reminderDuration?.includes(7)} onChange={(e)=>handleRemainderDurationChange(e, 7)} size="small" />}
                                    label="7 Days"
                                  />
                                  <FormControlLabel
                                    control={<Checkbox checked={reminderDuration?.includes(1)} onChange={(e)=>handleRemainderDurationChange(e, 1)} size="small" />}
                                    label="1 Day"
                                  />

                                </Stack>
                              </StyledFormControl>
                            </Grid>


                          </Grid>
                        </CardContent>

                      )}
                      <FormControlLabel
                        control={<Checkbox checked={isDistanceBased} onChange={handleDistanceBasedChange} />}
                        label="Distance"
                      />

{isDistanceBased && isRecurring && (
                        <CardContent sx={{ px: 3 }}>
                          <Grid container spacing={3}>
                          <Grid item lg={6}>
                              <StyledFormControl fullWidth>
                                <StyledFormControlLabel  required>
                                   Next Service Distance
                                </StyledFormControlLabel>
                                <StyledTextField
                                {...register("nextServiceDistance", {
                                  required: {
                                    value: true,
                                    message: "Please select Service Distance",
                                  },
                                  validate: (fieldValue) => {
                                   
                                    if(currentOdometer != "NA" && parseInt(currentOdometer,10) >= fieldValue) {
                                      
                                      return "Please choose a value greater than current odometer"
                                    }
                                     return fieldValue !== "0" || " Please choose a value greater than current odometer";
                                     
                                  },
                                })}
                                  size="small"
                                  fullWidth
                                  type="number"
                                  value={nextServiceDistance}
                                  onChange={(e) => setNextServiceDistance(e.target.value)}
                                  InputProps={{ inputProps: { min: 0 } }}
                                />
                              </StyledFormControl>
                                <FormHelperText error={errors.nextServiceDistance && true} sx={{display:"contents"}}>
                                  {errors.nextServiceDistance?.message}
                                </FormHelperText>
                             
                            </Grid>
                            <Grid item lg={6}>
                              <StyledFormControl fullWidth>
                                <StyledFormControlLabel  required>
                                   Current Odometer Reading
                                </StyledFormControlLabel>
                                <StyledTextField
                                  size="small"
                                  fullWidth
                                  value={currentOdometer}
                                  // onChange={(e) => setCurrentOdometer(e.target.value)}
                                  disabled
                                  InputProps={{ inputProps: { min: 0 } }}
                                />
                              </StyledFormControl>
                            </Grid>
                            <Grid item lg={6}>
                              <StyledFormControl fullWidth>
                                <StyledFormControlLabel required>
                                  Recurrence Distance
                                </StyledFormControlLabel>
                                <StyledTextField
                                  {...register("recurrenceDistance", {
                                    required: {
                                      value: true,
                                      message: "Please select recurrence Distance ",
                                    },
                                    validate: (fieldValue) => {
                                      console.log(fieldValue);
                                      return fieldValue !== "0" || "Please select a valid recurrence Distance ";
                                    },
                                  })}
                                  size="small"
                                  fullWidth
                                  type="number"
                                  value={distanceRange}
                                  onChange={(e) => setDistanceRange(e.target.value)}
                                  InputProps={{ inputProps: { min: 0 } }}
                                />
                              </StyledFormControl>
                              <FormHelperText error={errors.recurrenceDistance && true} sx={{display:"contents"}}>
                                  {errors.recurrenceDistance?.message}
                                </FormHelperText>
                            </Grid>
                            <Grid item lg={6}>
                              <StyledFormControl fullWidth>
                                <StyledFormControlLabel  >
                                   Reminder Distance
                                </StyledFormControlLabel>
                                <StyledTextField
                                {...register("reminderDistance", {
                                  // required: {
                                  //   value:true,
                                  //   message: "Please select reminderDistance  ",
                                  // },
                                  validate: (fieldValue) => {
                                    if(currentOdometer != "NA" && parseInt(currentOdometer,10) >= fieldValue && fieldValue !== "") {

                                      return "Please choose a value greater than current odometer"
                                    }
                                    else {
                                      return true;
                                    }
                                  },
                                })}
                                  size="small"
                                  fullWidth
                                  type="number"
                                  value={remainderDistance}
                                  onChange={(e) => setRemainderDistance(e.target.value)}
                                  InputProps={{ inputProps: { min: 0 } }}
                                />
                              </StyledFormControl>
                              <FormHelperText error={errors.reminderDistance && true} sx={{display:"contents"}}>
                                  {errors.reminderDistance?.message}
                                </FormHelperText>
                            </Grid>
                          </Grid>
                        </CardContent>
                      )}
                    </Stack>
                  </StyledFormControl>
                </Grid>
              )}


            </Grid>
          </CardContent>
          {recurenceType === 1 &&
            <CardContent>
              <Grid container spacing={3}>
                <Grid item lg={12}>
                  <StyledFormControl fullWidth>
                    <StyledFormControlLabel >
                    Notify me
                    </StyledFormControlLabel>
                    <Stack direction="row" spacing={0}>
                      <FormControlLabel
                        control={<Checkbox checked={reminderDuration?.includes(30)} onChange={(e)=>handleRemainderDurationChange(e, 30)} />}
                        label="30 Days"
                      />
                      <FormControlLabel
                        control={<Checkbox checked={reminderDuration?.includes(15)} onChange={(e)=>handleRemainderDurationChange(e, 15)} />}
                        label="15 Days"
                      />
                      <FormControlLabel
                        control={<Checkbox checked={reminderDuration?.includes(7)} onChange={(e)=>handleRemainderDurationChange(e, 7)} />}
                        label="7 Days"
                      />
                      <FormControlLabel
                        control={<Checkbox checked={reminderDuration?.includes(1)} onChange={(e)=>handleRemainderDurationChange(e, 1)} />}
                        label="1 Day"
                      />

                    </Stack>
                  </StyledFormControl>
                </Grid>

              </Grid>
            </CardContent>
          }
        </StyledCardShadow>
        <CardActions>
          <StyledFormButtonsRow direction="row" sx={{ mt: 2 }} spacing={2}>
            <SoftButton
              className="error"
              size="small"
              onClick={handleCancelClick}
              color="error"
            >
              Cancel
            </SoftButton>
            {!clickedval && <SoftButton
              size="small"
              className="success"
              color="success"
              type="submit"
            >
              {isSaveLoading ? (
                <PulseLoader
                  color={theme.palette.success.contrastText}
                  size={6}
                />
              ) : (
                "Update"
              )}
            </SoftButton>}
          </StyledFormButtonsRow>
        </CardActions>
      </form>
    </Box>
  );
}

export default EditServices;