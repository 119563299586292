import React, { useState, useEffect, useMemo } from "react";
import { Box } from "@mui/system";

import { Breadcrumb } from "../shared/Breadcrumbs";
import { DataTable } from "../shared/data_table/DataTable";

import CommonDropDown from "./viewAlertDropDown";
import LogServices from "../../services/logService";
import { format } from "date-fns";
import { CardHeader, CardContent, Button } from "@mui/material";
import { StyledCardShadow, StyledPageLayout } from "../../theme/StyledEle";
import MouseOverPopover from "../shared/PopOver";
import ReportServices from "../../services/reportServices";
import AlertDropdown from "./AlertsDropdown/AlertDropdown";
import { useViewAlertsMutation } from "../../store/slice/AlertSlice/AlertSlice";
import { is } from "immutable";
import { useAuditLogMutation } from "../../store/slice/ApiSlice";
import GetLocation from "../Location";
const ViewAlert = () => {
  let userData = JSON.parse(localStorage.getItem("userData"));
  let resellerId = userData?.resellerId;
  let orgId = userData?.orgId;
  let userId = userData?.id;
  let orgName = userData?.orgName;
  let userName = userData?.userName;
  let orgType = userData?.userOrgType

  const [responseData, setResponseData] = useState([]);
  const [newPage, setNewPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [totalElements, setTotalElements] = useState(5);
  const [totalPages, setTotalPages] = useState(1);

  const [auditLog] = useAuditLogMutation();

  let auditPayload = {
    //Audit Common Payload
    orgId: orgId,
    userId: userId, 
    orgName: orgName, 
    userName: userName,
  };

  useEffect(async () => {
    auditPayload.message = "Alerts > View Alerts";
    auditPayload.action = "View";
    auditPayload.featureName = "Alerts";
    auditPayload.customerName = "All";
    auditPayload.fleetName = "All";
    auditPayload.result = "Success";
    auditPayload.access = "Portal";
    auditPayload.severity = "INFO";
    auditLog(auditPayload);
    window.localStorage.setItem('userData', JSON.stringify({...userData, allAlertCount:""}))
  }, []);

  const text =
    "Alerts captured are based on assignment (via Alerts > Assign Alerts) for each vehicle at the time of occurrence.";


  const columns = useMemo(
    () => [
      {
        Header: "Date",
        accessor: "gpsDate",
        width:150,
      },
      // {
      //   Header: "Region",
      //   accessor: "regionName",
      //   width:150,
      // },
      // {
      //   Header: "Dealer",
      //   accessor: "dealerName",
      //   width:150,
      // },
      {
        Header: "Customer",
        accessor: "customerName",
      },
      {
        Header: "Fleet",
        accessor: "fleetName",
      },
      {
        Header: "Vehicle No",
        accessor: "vehicleNo",
        width:180,
      },
      {
        Header: "Alert Type",
        accessor: "alertType",
        width:180,
      },
      {
        Header: "Location",
        accessor: "gpsLocation",
        width:180,
        Cell: ({ row }) => {
          return(
            row.original.gpsLocation !== "" &&   row.original.gpsLocation !== "NA" ?
          <GetLocation row={row} columnName={row.original.gpsLocation}/> : '-'
          )
        }
      },

      {
        Header: "Description",
        accessor: "message",
        width:350,
      },
    ],
    []
  );

    
  let initialState1 = {};
  var arr = [];
  if (orgType === 3) {
    arr.push("regionName");
    arr.push("dealerName");
    arr.push("customerName");
    initialState1.hiddenColumns = arr;
  }else if(orgType === 5){
    arr.push("regionName");
    arr.push("dealerName");
    initialState1.hiddenColumns = arr;
  }else if(orgType === 1){
    arr.push("regionName");
    arr.push("dealerName");
    arr.push("customerName");
    arr.push("fleetName");
    initialState1.hiddenColumns = arr;
  }

  const [getViewAlertList, { data, isLoading, isSuccess }] =
    useViewAlertsMutation();
  const [payload, setpayload] = useState(null);
  const commonPayload = {
    resellerId: resellerId,
    orgType: orgType,
    logoName: "",
    columnName: "GPS_DATE",
    orderDir: "desc",
    isWeb: "true",
  };
  const getData = (data) => {
    commonPayload.orgId = data.fleetId;
    commonPayload.regionId = data.regionId;
    commonPayload.customerId = data.customerId;
    commonPayload.dealerId = data.dealerId;
    commonPayload.vehicleId = data.vehicleId;
    commonPayload.startDate = data.fromDate;
    commonPayload.endDate = data.toDate;
    commonPayload.alertType = data.alertType;
    commonPayload.searchText = "";
    commonPayload.page = newPage;
    commonPayload.size = rowsPerPage;
    setpayload(commonPayload);
    getViewAlertList(commonPayload);

    //Audit Log
    auditPayload.message = "Alerts > View Alerts > "+data.regionName+" > "+data.dealerName+" > "+data.customerName+" > "+data.fleetName+" > "+data.vehicleName+" > "+data.alertTypeName+" > "+data.selectedPeriodName+"("+data.fromDate+"-"+data.toDate+")"+" > "+"Submit";
    auditPayload.action = "Submit";
    auditPayload.featureName = "Alerts";
    auditPayload.customerName = "All";
    auditPayload.fleetName = "All";
    auditPayload.result = "Success";
    auditPayload.access = "Portal";
    auditPayload.severity = "INFO";
    auditPayload.userName = userName;
    auditLog(auditPayload);
    
  };

  const handleSearch = async (searchText) => {
    payload.searchText = searchText;
    if (payload.searchText.length >= 3) {
      getViewAlertList(payload);
    } else if (payload.searchText.length === 0) {
      getViewAlertList(payload);
    }
  };

  //Page Change
  const pageChange = (newPage, rowsPerPage) => {
    setNewPage(newPage);
    setRowsPerPage(rowsPerPage);
    payload.page = newPage;
    payload.size = rowsPerPage;
    getViewAlertList(payload);
  };
  const onChangeXcl = async () => {
    try {
      var url =
        process.env.REACT_APP_API_BASE_URL +
        "Alerts/getReportListCSV.csv?regionId=" +
        payload.regionId +
        "&resellerId=" +
        payload.resellerId +
        "&startDate=" +
        payload.startDate +
        "&endDate=" +
        payload.endDate +
        "&dealerId=" +
        payload.dealerId +
        "&orgId=" +
        payload.orgId +
        "&vehicleId=" +
        payload.vehicleId +
        "&customerId=" +
        payload.customerId +
        "&searchText=" +
        payload.searchText +
        "&alertType=" +
        payload.alertType +
        "&orgType=" +
        payload.orgType +
        "&columnName=" +
        payload.columnName +
        "&orderDir=" +
        payload.orderDir +
        "&isWeb=" +
        payload.isWeb;
       window.open(url);
     

      auditPayload.message = "Alerts > View Alerts > Export Excel";
      auditPayload.action = "View";
      auditPayload.featureName = "Alerts";
      auditPayload.customerName = "All";
      auditPayload.fleetName = "All";
      auditPayload.result = "Success";
      auditPayload.access = "Portal";
      auditPayload.severity = "INFO";
      auditLog(auditPayload);

      fetch(url, {
        headers: {
          Authorization:
            "Bearer " + JSON.parse(window.localStorage.getItem("jwToken")),
        },
      }) // FETCH BLOB FROM IT
        .then((response) => {
          response.blob();
        })
        .then((blob) => {})
        .catch((err) => {});
    } catch (error) {}
  };

  useEffect(() => {
    if (isSuccess) {
      setResponseData([]);
      if (data.datas !== null) {
        setResponseData(data.datas.content);
        setTotalElements(data.datas.totalElements);
        setTotalPages(data.datas.totalPages);
      }
    }
  }, [isSuccess]);

  return (
    <StyledPageLayout>
      <Breadcrumb
        mainDiv="Alerts"
        subDiv="View Alerts"
        includePopup={true}
      ></Breadcrumb>
      <MouseOverPopover text={text} title={"View Alerts"} />
      <Box>
        {/* <CommonDropDown
          onSubmit={handleSubmit}
          isShowVeh={true}
          isStatus={true}
          callbackLoadFn={fetchAlertsDetails}
        /> */}
        <AlertDropdown getData={getData} isShowAll={true} allowedDays={7} allowedMonth={3} createDateStatus={false} showLast7Days={true} createDateValue={"1"}/>

        <StyledCardShadow sx={{ mt: 2 }}>
          <CardContent sx={{ py: 2 }}>
            <DataTable
              payload={payload}
              exportWithAll={true}
              initialState={initialState1}
              isLoading={isLoading}
              includeSearchBox={true}
              columns={columns}
              data={responseData}
              styles={{ maxWidth: "100vw" }}
              numberOfStickyColumns={1}
              page={newPage}
              rowsPerPage={rowsPerPage}
              callbackChangePage={pageChange}
              totalElements={totalElements}
              totalPages={totalPages}
              handleChange={handleSearch}
              includeExportButton={true}
              onChangeXcl={onChangeXcl}
            />
          </CardContent>
        </StyledCardShadow>
      </Box>
    </StyledPageLayout>
  );
};
export default ViewAlert;
