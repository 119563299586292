import { useState, useEffect, useMemo } from "react";
import { useAuditLogMutation, useServicePlanListMutation, useUpcomingServicePlansMutation } from "../../store/slice/ApiSlice";
import { useGeoZoneGridListViewMutation } from "../../store/slice/GeozoneSlice/GeozoneSlice";
import { SoftButton, StyledPageLayout } from "../../theme/StyledEle";
import MouseOverPopover from "../shared/PopOver";
import { Breadcrumb } from "../shared/Breadcrumbs";
import FilterDropdown from "../NewDropdown/FilterDropdown";
import { DataTable } from "../shared/data_table/DataTable";
import { CardContent, Chip, Typography, useTheme } from "@mui/material";
import { StyledCardShadow } from "../../theme/StyledEle";
import { Box } from "@mui/system";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { FiPlus } from "react-icons/fi";
import DrawerModel from "../../Utils/DrawerModel";
import CreateServiceForm from "./CreateServiceForm";
import { ExpandTable } from "../shared/data_table/ExpandTable";
import { alpha } from "@material-ui/core";


const ListServicePlans = ({ onEditService, onViewOrg, menuUser }) => {

  const [
    getServicePlanList,{ data: servicePlanData, isLoading: getServicePlanLoading, isSuccess: getServicePlanSuccess },] = useUpcomingServicePlansMutation();

  let userData = JSON.parse(localStorage.getItem("userData"));
  let resellerId = userData.resellerId;
  let orgId = userData.orgId;
  let userId = userData.id;
  let orgName = userData.orgName;
  let userName = userData.userName;
  const [responseData, setResponseData] = useState([]);
  const [newPage, setNewPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [totalElements, setTotalElements] = useState(5);
  const [totalPages, setTotalPages] = useState(1);
  const [payload, setPayload] = useState(null);
  const [auditLog] = useAuditLogMutation();
  const [deleteItem, setDeleteItem] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const theme = useTheme();
  const [commonFilter, setCommonFilter] = useState([]);
  const [selectRows, setSeletedRows] = useState(null);
  const [selectRowId, setSelectRowId] = useState(null);

  let auditPayload = {
    orgId: orgId,
    userId: userId,
    orgName: orgName,
    userName: userName,
  }

  useEffect(async () => {
    auditPayload.message = "Service Plan > List Service Plan > GridView";
    auditPayload.action = "View";
    auditPayload.featureName = "Service Plan Grid";
    auditPayload.customerName = "All";
    auditPayload.fleetName = "All";
    auditPayload.result = "Success";
    auditPayload.access = "Portal";
    auditPayload.severity = "INFO";
    auditLog(auditPayload);
  }, []);

  const columns = useMemo(() =>
    [
      {
        Header: "Vehicle No",
        accessor: "vehicleNo",
        width: 120,
      },
      {
        Header: "Service Type",
        accessor: "serviceTypeLabel",
      },
      {
        Header: "Recurrence Duration",
        accessor: "recurrenceDurationLabel",
      },
      {
        Header: "Next Service Date",
        accessor: "serviceDate",
      },
      // {
      //   Header: "Recurrence Type",
      //   accessor: "recurrenceTypeLabel",
      // },
      {
        Header: "Recurrence Distance",
        accessor: "recurrenceDistance",
      },
      {
        Header: "Next Service Distance",
        accessor: "nextServiceDistance",
      },
      {
        Header: "Status",
        accessor: "serviceStatus",
        Cell:({row}) => {
          if(row.original.serviceStatus !== '-' ){
              return <Typography  variant="body2"
              color={row.original.serviceStatus ==='Due for service' ? 'primary':row.original.serviceStatus ==='Completed'? 'success.main':'error.main'}
              sx={{ padding: "0px 3px",fontSize: "12px",fontStyle: "normal", fontWeight: "none" }}>
               {row.original.serviceStatus}</Typography>
          }else{
              return row.original.serviceStatus
          }
        }
      },

      // {
      //   Header: "Action",
      //   accessor: "buttonEditDelete",
      //   width: 120,
      // },

    ], [resellerId]);

  let commonPayload = {};
  const onSubmitForm = (data) => {
    setNewPage(0);
    setRowsPerPage(25);
    commonPayload.resellerId = resellerId;
    commonPayload.regionId = data.regionId;
    commonPayload.dealerId = data.dealerId;
    commonPayload.customerId = data.customerId;
    commonPayload.orgId = data.fleetId;
    commonPayload.fleetId = data.fleetId;
    if (data.customerId == -1) {
      commonPayload.orgId = resellerId;
    }
    commonPayload.vehicleId = data.vehicleId;
    commonPayload.search = "";
    commonPayload.sortBy = "desc";
    commonPayload.page = newPage;
    commonPayload.size = rowsPerPage;
    commonPayload.serviceType = -1;
    commonPayload.recurrenceType = -1;

    //Service Call
    setPayload(commonPayload);
    getServicePlanList(commonPayload);

    auditPayload.message = "Service Plan > Upcoming service Plans > " + data.regionName + " > " + data.dealerName + " > " + data.customerName + " > " + data.fleetName + " > " + data.vehicleName + " > " + data.selectedPeriodName + "(" + data.fromDate + "-" + data.toDate + ")" + " > " + "Submit";
    auditPayload.action = "Submit";
    auditPayload.featureName = "ServicePlan";
    auditPayload.customerName = "All";
    auditPayload.fleetName = "All";
    auditPayload.result = "Success";
    auditPayload.access = "Portal";
    auditPayload.severity = "INFO";
    auditPayload.userName = userName;
    //auditLog(auditPayload);
  };

  const handleSearch = async (searchText) => {
    payload.search = searchText;
    if (payload.search.length >= 3) {
      payload.page = 0;
      payload.rowsPerPage = 25;
      setNewPage(0);
      setRowsPerPage(25);
      getServicePlanList(payload);
    } else if (payload.search.length === 0) {
      payload.page = 0;
      payload.rowsPerPage = 25;
      setNewPage(0);
      setRowsPerPage(25);
      getServicePlanList(payload);

    }
  };

  //Page Change
  const pageChange = (newPage, rowsPerPage) => {
    setRowsPerPage(rowsPerPage);
    payload.size = rowsPerPage;
    if (totalElements < rowsPerPage * newPage) {
      setNewPage(0)
      payload.page = 0;
      getServicePlanList(payload);
    } else {
      setNewPage(newPage);
      payload.page = newPage;
      getServicePlanList(payload);
    }
  };

  useEffect(() => {
    if (getServicePlanSuccess) {

      if (servicePlanData.data !== null) {

        setResponseData(servicePlanData.data.content);
        setTotalElements(servicePlanData.data.totalElements);
        setTotalPages(servicePlanData.data.totalPages);

        var dataStr = {};
        dataStr.edit = false;
        dataStr.delete = false;
        dataStr.view = false;
        dataStr.excel = false;
        dataStr.pdf = false;
        var strval;
        strval = menuUser.split(",");
        for (var i = 0; i < strval.length; i++) {
          if (parseInt(strval[i]) === 4) {
            dataStr.edit = true;
          }
          if (parseInt(strval[i]) === 3) {
            dataStr.delete = true;
          }
          if (parseInt(strval[i]) === 6) {
            dataStr.pdf = true;
          }
          if (parseInt(strval[i]) === 5) {
            dataStr.excel = true;
          }
          if (parseInt(strval[i]) === 2) {
            dataStr.view = false;
          }
          dataStr.edit = true;
          dataStr.view = false;
        }
        setCommonFilter(dataStr);
      }
    }

  }, [getServicePlanSuccess]);

  const onEdit = (itemEdited) => {
    onEditService(itemEdited);

    auditPayload.message = "Admin > ServicePlan > List > Edit";
    auditPayload.action = "Edit";
    auditPayload.featureName = "ServicePlan";
    auditPayload.customerName = "All";
    auditPayload.fleetName = "All";
    auditPayload.result = "Success";
    auditPayload.access = "Portal";
    auditPayload.severity = "INFO";
    auditPayload.userName = userName;
    auditLog(auditPayload);
  };

  const onDelete = async (itemDeleted) => {
    setDeleteItem(itemDeleted);
    navigate(`#/id=${itemDeleted.id}`);
  };


  const onView = async (handleOrgView) => {
    onViewOrg(handleOrgView);

    auditPayload.message = "Admin > ServicePlan > List > View";
    auditPayload.action = "View";
    auditPayload.featureName = "ServicePlan";
    auditPayload.customerName = "All";
    auditPayload.fleetName = "All";
    auditPayload.result = "Success";
    auditPayload.access = "Portal";
    auditPayload.severity = "INFO";
    auditPayload.userName = userName;

    auditLog(auditPayload);
  };

  const getRow = (selectRow, rowId) => {
    setSelectRowId(rowId);
    setSeletedRows(selectRow);
  };
  return (
    <>
      <FilterDropdown
        getData={onSubmitForm}
        status={false}
        allowDays={7}
        allowToday={true}
        last6Month={true}
        showCalendar={false}

      />
      <Box sx={{ mt: 2, display: 'flex', justifyContent: 'end' }}>
        <SoftButton variant='contained' color='success' size='small' className='success-soft' startIcon={<FiPlus />} onClick={() => navigate('#create')}>
          Create Service Plan
        </SoftButton>
      </Box>
      {/* Create Service Plan */}
      <DrawerModel position={'right'} width={'35vw'} title={'Create Service Plan'}>
        <CreateServiceForm reload={onSubmitForm} payload={payload}/>
      </DrawerModel>
      <Box>
        <StyledCardShadow sx={{ mt: 2 }}>
          <CardContent sx={{ py: 1 }}>
            <ExpandTable
              //onEdit={onEdit}
              // onDelete={onDelete}
              columns={columns}
              // onView={onView}
              data={responseData}
              styles={{ maxWidth: "100vw" }}
              numberOfStickyColumns={2}
              page={newPage}
              exportWithAll = {false}
              includeExportButton = {false}
              includeSearchBox={false}
              handleChange={handleSearch}
              rowsPerPage={rowsPerPage}
              callbackChangePage={pageChange}
              totalElements={totalElements}
              totalPages={totalPages}
              isLoading={getServicePlanLoading}
              commonFilter={commonFilter}
              selectRow={getRow}
            />
          </CardContent>
        </StyledCardShadow>
      </Box>
    </>
  );


};


export default ListServicePlans;