import React, { useCallback, useEffect, useRef, useState } from 'react'
import { StyledPageLayout } from '../../theme/StyledEle'
import { Box, Grid, Typography } from '@mui/material'
import { Chart, DG_Card } from './Chart'
import { Sync_Chat1, UKMap, vehicle_animation, vehicle_count_animation, vehicle_Offline_duration, vehicle_Online_Offline_Status, vehicle_online_status } from './Data'
import * as echarts from "echarts/core";
import Donut from './Donut'
import HalfDount from './HalfDonut'
import Line from './Line'
import { useGetDashboardDataMutation, useGetDistanceDataMutation, useGetDurationDataMutation } from '../../store/slice/DashboardSlice/dashboardSlice'
import CountUp from 'react-countup'
import Piechart from './Piechart'
function Edashbord({payload}) {
    let userData = JSON.parse(localStorage.getItem("userData"));
    let resellerId = userData?.resellerId;
    const groupId = 'connect-chart';
    const echartsInstanceStore = {};
    const animeRef = useRef();

    const onChartReadyCallback = useCallback((instance) => {
        if (instance) {
            if (!echartsInstanceStore[instance.id]) {
                instance.group = groupId;
                echartsInstanceStore[instance.id] = instance;
            }
        }

        if (Object.entries(echartsInstanceStore).length > 1) {
            echarts.disconnect(groupId);
            echarts.connect(groupId);
        }
    }, [groupId])



    const [data, setData] = useState([])
    const [response, setResponse] = useState([])
    const [responseData, setResponseData] = useState([])
    const [durationData, setDurationData] = useState([])
    let now = new Date(2024, 9, 9);
    let oneDay = 24 * 3600 * 1000;
    let value = Math.random() * 1000;

    function randomData() {
        now = new Date(+now + oneDay);
        value = value + Math.random() * 21 - 10;
        return {
            name: now.toString(),
            value: [
                [now.getFullYear(), now.getMonth() + 1, now.getDate()].join('/'),
                Math.round(value)
            ]
        };
    }

    const dataAnime = () => {
        for (var i = 0; i < 1000; i++) {
            const timeData = randomData();
            setData(oldArray => [...oldArray, timeData])
        }
    }

    useEffect(() => {
        dataAnime();
        onSubmit()
    }, [payload])

    //   useEffect(() => {
    //     if(animeRef.current !== undefined){
    //         setInterval(function () {
    //             for (var i = 0; i < 5; i++) {
    //                 setData(oldData => [...oldData, randomData()]);
    //             }
    //             // animeRef.current.getEchartsInstance().setOption({
    //             //   series: [
    //             //     {
    //             //       data: data
    //             //     }
    //             //   ]
    //             // });
    //           }, 5000);
    //     }
    //   },[animeRef.current])

    const [getDashData, {isLoading}] = useGetDashboardDataMutation();
    const [getDistanceData,{isLoading:isDistLoading}] = useGetDistanceDataMutation();
    const [getDurationData,{isLoading:isDurationLoading}] = useGetDurationDataMutation();

    let commonPayload = {};
    const onSubmit = async () => {
        commonPayload.resellerId = resellerId;
        commonPayload.customerId = payload?.customerId;
        commonPayload.orgId = payload?.fleetId;
        const res = await getDashData(commonPayload);
        setResponse(res?.data?.content[0])
        commonPayload.startDate = payload?.fromDate;
        commonPayload.selectedPeriod = payload?.selectedPriod;
        const resData = await getDistanceData(commonPayload);
        setResponseData(resData?.data?.content[0]);
        const durationData = await getDurationData(commonPayload);
        setDurationData(durationData?.data?.content[0]);
    }

    //sync 1
const Sync_Chat1 =  {
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'cross',
        crossStyle: {
          color: '#999'
        }
      }
    },
    toolbox: {
      feature: {
        //dataView: { show: true, readOnly: false },
        //magicType: { show: true, type: ['line', 'bar'] },
        //restore: { show: true },
        //saveAsImage: { show: true }
      }
    },
    legend: {
      data: ['Distance Traveled'],
      bottom: '5%',
      left: 'center',
      show:true,
    },
    xAxis: [
      {
        type: 'category',
        data: responseData?.xaxis?.data,
        axisPointer: {
          type: 'shadow'
        }
      }
    ],
    yAxis: [
      {
        type: 'value',
        name: 'Distance Traveled',
        min: 0,
        max: 1000,
        interval: 100,
        axisLabel: {
          formatter: '{value} mi'
        }
      },
    ],
    series: [
      {
        name: 'Distance Traveled',
        type: 'bar',
        tooltip: {
          valueFormatter: function (value) {
            return value + ' mi';
          }
        },
        data: responseData?.series?.data
      },
    ]
}

    return (
        <StyledPageLayout>
            <Box>
                 <Grid container spacing={2}>
                 <Grid item lg={2.4}>
                        <DG_Card title={null} cardStyle={{ background: '#fb6107 !important' }} >
                            <Box sx={{ textAlign: 'center', p: 2, color: '#fff !important' }}>
                                <Typography variant='subtitle1' sx={{ fontWeight: 500, color: '#fff !important' }}>Total Vehicle</Typography>
                               <CountUp 
                                    end={response !== undefined && response.card !== undefined && response?.card[0]?.totalCount}
                                    duration={5}
                                    redraw={true}
                                >
                                        {({ countUpRef }) => (
                                         <Typography variant='h4' sx={{ fontWeight: 600, color: '#fff !important' }} ref={countUpRef}/>
                                        )}
                                </CountUp> 
                                </Box>
                        </DG_Card>
                    </Grid>
                    <Grid item lg={2.4}>
                        <DG_Card title={null} cardStyle={{ background: '#60d394 !important' }} >
                            <Box sx={{ textAlign: 'center', p: 2, color: '#fff !important' }}>
                                <Typography variant='subtitle1' sx={{ fontWeight: 500, color: '#fff !important' }}>Moving</Typography>
                                <CountUp 
                                    end={response !== undefined && response.card !== undefined && response?.card[0]?.moveCount}
                                    duration={5}
                                    redraw={true}
                                >
                                        {({ countUpRef }) => (
                                         <Typography variant='h4' sx={{ fontWeight: 600, color: '#fff !important' }} ref={countUpRef}/>
                                        )}
                                </CountUp> 
                            </Box>
                        </DG_Card>
                    </Grid> 
 <Grid item lg={2.4}>
                        <DG_Card title={null} cardStyle={{ background: '#f94144 !important' }} >
                            <Box sx={{ textAlign: 'center', p: 2, color: '#fff !important' }}>
                                <Typography variant='subtitle1' sx={{ fontWeight: 500, color: '#fff !important' }}>Stop</Typography>
                                <CountUp 
                                    end={response !== undefined && response.card !== undefined && response?.card[0]?.stopCount}
                                    duration={5}
                                    redraw={true}
                                >
                                        {({ countUpRef }) => (
                                         <Typography variant='h4' sx={{ fontWeight: 600, color: '#fff !important' }} ref={countUpRef}/>
                                        )}
                                </CountUp> 
                            </Box>
                        </DG_Card>
                    </Grid>
                    <Grid item lg={2.4}>
                        <DG_Card title={null} cardStyle={{ background: '#00b4d8 !important' }} >
                            <Box sx={{ textAlign: 'center', p: 2, color: '#fff !important' }}>
                                <Typography variant='subtitle1' sx={{ fontWeight: 500, color: '#fff !important' }}>Idle</Typography>
                                <CountUp 
                                    end={response !== undefined && response.card !== undefined && response?.card[0]?.idleCount}
                                    duration={5}
                                    redraw={true}
                                >
                                        {({ countUpRef }) => (
                                         <Typography variant='h4' sx={{ fontWeight: 600, color: '#fff !important' }} ref={countUpRef}/>
                                        )}
                                </CountUp> 
                            </Box>
                        </DG_Card>
                    </Grid>
                    <Grid item lg={2.4}>
                        <DG_Card title={null} cardStyle={{ background: '#6d6a75 !important' }} >
                            <Box sx={{ textAlign: 'center', p: 2, color: '#fff !important' }}>
                                <Typography variant='subtitle1' sx={{ fontWeight: 500, color: '#fff !important' }}>Offline</Typography>
                                <CountUp 
                                    end={response !== undefined && response.card !== undefined && response?.card[0]?.offlineCount}
                                    duration={5}
                                    redraw={true}
                                >
                                        {({ countUpRef }) => (
                                         <Typography variant='h4' sx={{ fontWeight: 600, color: '#fff !important' }} ref={countUpRef}/>
                                        )}
                                </CountUp> 
                            </Box>
                        </DG_Card>
                    </Grid>   
                    <Grid item lg={4}>
                        <DG_Card title={'Vehicle Online & Offline Status'}>
                            <Donut  data={response?.piechart} isLoading={isLoading}/>
                        </DG_Card>
                    </Grid>
                    <Grid item lg={4}>
                        <DG_Card title={'Vehicle Online Status'}>
                            <HalfDount id={13} data={response?.halfDonut} isLoading={isLoading}/>
                        </DG_Card>
                    </Grid>
                    <Grid item lg={4}>
                        <DG_Card title={'Vehicle offline by duaraiton'}>
                            <HalfDount id={12} data={response?.halfDonut1} isLoading={isLoading}/>
                        </DG_Card>
                    </Grid>
                    <Grid item lg={4}>
                        <DG_Card title={'Moving / Idle / Stop / Offline (Minutes)'}>
                            <Piechart  data={durationData?.vehicleDuration} isLoading={isDurationLoading}/>
                        </DG_Card>
                    </Grid>
                    <Grid item lg={4}>
                        <DG_Card title={null} cardStyle={{ background: '#60d394 !important' ,height:'100%',display:'flex',alignItems:'center',justifyContent:'center',width:'100%'}} >
                            <Box sx={{ textAlign: 'center', p: 2, color: '#fff !important' }}>
                                <Typography variant='subtitle1' sx={{ fontWeight: 500, color: '#fff !important' }}>Distance Traveled (mi)</Typography>
                               <CountUp 
                                    end={durationData !== undefined && durationData?.totalDistance}
                                    duration={1}
                                    redraw={true}
                                >
                                        {({ countUpRef }) => (
                                         <Typography variant='h2' sx={{ fontWeight: 600, color: '#fff !important' }} ref={countUpRef}/>
                                        )}
                                </CountUp> 
                                </Box>
                        </DG_Card>
                    </Grid>
                    <Grid item lg={4}>
                        <DG_Card title={'Fuel Type Count'}>
                            <Piechart  data={responseData?.fuelType} isLoading={isDistLoading}/>
                        </DG_Card>
                    </Grid>
                    {/* <Grid item lg={4}>
                        <DG_Card title={'Vehicle offline by duaraiton'}>
                            <HalfDount id={12} data={response?.halfDonut1} isLoading={isLoading}/>
                        </DG_Card>
                    </Grid> */}
                    {/* <Grid item lg={2}>
                        <DG_Card title={'Vehicle Online & Offline Status'}>
                            <Donut />
                        </DG_Card>
                    </Grid> */}
                    {/* <Grid item lg={12}>
                    <DG_Card title={'Vehicle offline by duaraiton'}>
                        <Line />
                    </DG_Card>
                </Grid> */}
                    {/* <Grid item lg={2}>
                        <DG_Card title={'Vehicle distribution'}>
                            <Chart options={vehicle_Online_Offline_Status} />
                        </DG_Card>
                    </Grid> */}
                    {/* <Grid item lg={12}>
                        <DG_Card title={'Vehicle Count'}>
                            <Chart options={vehicle_count_animation(data)} ref={animeRef} />
                        </DG_Card>
                    </Grid> */}
                    <Grid item lg={12}>
                        <DG_Card title={'Distance Traveled (mi)'}>
                            <Chart options={Sync_Chat1} onChartReadyCallback={onChartReadyCallback} isLoading={isDistLoading}/>
                        </DG_Card>
                    </Grid>
                    {/* <Grid item lg={6}>
                        <DG_Card title={'Distance Travelled & Energy'}>
                            <Chart options={Sync_Chat1} onChartReadyCallback={onChartReadyCallback} />
                        </DG_Card>
                    </Grid> */}
                    {/* <Grid item lg={12}>
                        <DG_Card title={'Top 3 Vehicles by Distance'}>
                            <Chart options={vehicle_animation()} />
                        </DG_Card>
                    </Grid> */}
                    {/* <Grid item lg={3}>
                        <DG_Card title={'UK Map'}>
                            <Chart options={UKMap()} />
                        </DG_Card>
                    </Grid> */}
                </Grid>
            </Box>
        </StyledPageLayout>
    )
}

export default Edashbord