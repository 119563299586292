import { dashboardApi } from "../ApiSlice";

const BotSlice = dashboardApi.injectEndpoints({
    endpoints:builder => ({
        botUserMessage:builder.mutation({
            query:(body) => ({
                    url: "/detectIntentTexts",
                    method: "POST",
                    body:body
            })
        }),
        botGptUserMessage:builder.mutation({
            query:(body) => ({
                    url: "/api/chat/ask",
                    method: "POST",
                    body:body
            })
        })
    })
})

export const { useBotUserMessageMutation,useBotGptUserMessageMutation } = BotSlice;